import React, {useRef, useEffect} from "react";

interface DynamicTextFieldsProps {
	fieldId: string;
	textFields: {[key: string]: string[]}; // Define textFields as an object with string keys and string[] values
	setTextFields: React.Dispatch<
		React.SetStateAction<{[key: string]: string[]}>
	>;
}

const DynamicTextFields: React.FC<DynamicTextFieldsProps> = ({
	fieldId,
	textFields,
	setTextFields,
}) => {
	const textAreaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
	const [adding, setAdding] = React.useState(false);

	useEffect(() => {
		if (adding === true) {
			// Focus on the new element
			if (textFields?.[fieldId] && textFields?.[fieldId].length) {
				const currentLength = textFields?.[fieldId].length;
				if (currentLength > 0 && textAreaRefs.current[currentLength - 1]) {
					textAreaRefs.current[currentLength - 1]?.focus();
				}
				setAdding(false);
			}
		}
	}, [adding, textFields, fieldId]);

	const handleAddTextField = (): void => {
		setTextFields((prevTextFields) => ({
			...prevTextFields,
			[fieldId]: [...(prevTextFields[fieldId] || []), ""],
		}));
		setAdding(true);
	};

	const handleRemoveTextField = (index: number): void => {
		setTextFields((prevTextFields) => ({
			...prevTextFields,
			[fieldId]: prevTextFields[fieldId].filter((_, i) => i !== index),
		}));
	};

	const handleChange = (index: number, value: string): void => {
		setTextFields((prevTextFields) => ({
			...prevTextFields,
			[fieldId]: prevTextFields[fieldId].map((item: string, i: number) =>
				i === index ? value : item
			),
		}));
	};

	const handleKeyPress = (
		event: React.KeyboardEvent<HTMLTextAreaElement>,
		index: number
	): void => {
		if (event.key === "Enter") {
			event.preventDefault();
			handleAddTextField();
		}
	};

	const handleButtonPress = (): void => {
		handleAddTextField();
	};

	return (
		<div>
			{textFields?.[fieldId] &&
				textFields?.[fieldId].map((text: any, index: any) => (
					<div key={index} style={{position: "relative"}}>
						<textarea
							ref={(el) => (textAreaRefs.current[index] = el)}
							placeholder="Voer tekst in..."
							value={text}
							onChange={(e) => handleChange(index, e.target.value)}
							onKeyPress={(e) => handleKeyPress(e, index)}
							style={{
								margin: "0 0 10px", // Adjusted margin
								padding: "8px",
								borderRadius: "4px",
								fontSize: "14px",
								marginTop: index === 0 ? "0px" : "-10px",
								fontFamily: "Karla",
								maxHeight: "12px", // Set minimum height
								minWidth: "40vw", // Set minimum width
								minHeight: "25px",
								resize: "vertical", // Allow vertical resizing
								border: "none", // Remove border
								outline: "none", // Remove outline
								boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
							}}
						/>
						<button
							onClick={() => handleRemoveTextField(index)}
							style={{
								position: "absolute",
								top: "20%",
								right: "10px",
								transform: "translateY(-50%)",
								background: "none",
								border: "none",
								cursor: "pointer",
								fontSize: "16px",
								color: "#999",
							}}
						>
							&#10005; {/* Unicode character for cross icon */}
						</button>
					</div>
				))}
			{(textFields?.[fieldId] === undefined ||
				textFields?.[fieldId].length === 0) && (
				<button onClick={() => handleButtonPress()}>Voeg toe</button>
			)}
		</div>
	);
};

export default DynamicTextFields;
