import React, {useEffect, useState} from "react";
import {
	fetchDocData,
	fetchDocsData,
	mergeDoc,
	removeDoc,
} from "../../services/firebaseFunctions";
import FinancialOverview from "../../components/computation-financials/FinancialElement";
import {
	fetchPVComputation,
	fetchPDFTemplate,
} from "../../services/apiFunctions";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress component
// import { removeFolder } from "../../components/utils/s3ImageOperations";
import {useTranslation} from "react-i18next";

interface InteractionDataItem {
	id: string;
	fields: any; // Update the type accordingly
	computation_present: boolean;
	pdf_present: boolean;
	costs: any[]; // Update the type accordingly
	simultaneous?: {
		usage_data: any; // Update the type accordingly
	};
	production: any; // Update the type accordingly
}

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const allInteractionsPresent = (recordList: InteractionDataItem[]): boolean => {
	// Check if recordList is empty
	if (!recordList || recordList.length === 0) {
		return false;
	}

	// Check if all records in the record list have a PDF
	return recordList.every(
		(record) =>
			record.computation_present === true && record.pdf_present === true
	);
};

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionDataItem[];
	template: any; // Update the type accordingly
	generalData: any;
	pageReady: boolean;
}

const SolarEnergyResultSummary: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	template,
	generalData,
	pageReady,
}) => {
	const [displayData, setDisplayData] = useState<any>({});
	const [loading, setLoading] = useState<boolean>(false); // State to track loading state
	const [error, setError] = useState<boolean>(false); // State to track error state
	const {t} = useTranslation();
	useEffect(() => {
		if (interactionData) {
			if (interactionData.length > 0) {
				let status = allInteractionsPresent(interactionData);

				if (!(status === pageReady)) {
					mergeDoc("solar_energy_computations", updateId, {
						progress: {
							5: {
								done: status,
								name: "Computation results",
							},
						},
					});
				}
			}
		}
	}, [interactionData, updateId]);

	const functionCallResult = async (
		updateId: string,
		interactionId: string,
		requestPayload: any // Update the type accordingly
	) => {
		if (error === false) {
			const result = await fetchPVComputation(
				updateId,
				interactionId,
				requestPayload
			);
			if (result.data && interactionId && interactionId !== "") {
				mergeDoc(
					`solar_energy_computations/${updateId}/results`,
					`${interactionId}`,
					{inputs: requestPayload, outputs: result.data}
				);
				mergeDoc(
					`solar_energy_computations/${updateId}/computation_inputs`,
					`${interactionId}`,
					{computation_present: true, pdf_present: false}
				);
			} else {
				setError(true);
				setDisplayData({});
			}
		}
		return true;
	};

	useEffect(() => {
		const promiseArray = interactionData.map(async (interaction) => {
			if (
				interaction.computation_present === true &&
				interaction.pdf_present === false &&
				interaction.id
			) {
				// Return the Promise.all result for parallel execution of mergeDoc and fetchPDFTemplate
				return Promise.all([
					mergeDoc(
						`solar_energy_computations/${updateId}/computation_inputs`,
						`${interaction.id}`,
						{computation_present: true, pdf_present: true}
					),
					fetchPDFTemplate(
						updateId,
						interaction.id,
						"solar_energy_computations"
					),
				]);
			} else {
				// Return a resolved promise for interactions that do not meet the condition
				return Promise.resolve(null);
			}
		});

		// Use Promise.allSettled to wait for all promises to settle
		Promise.allSettled(promiseArray)
			.then((results) => {
				results.forEach((result, index) => {
					if (result.status === "fulfilled" && result.value) {
						console.log(`Operation ${index + 1} succeeded, pdf`, result);
					}
				});
			})
			.catch((error) => {
				console.error("An error occurred:", error);
			});
	}, [interactionData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (
					interactionData &&
					interactionData.length > 0 &&
					interactionData.find((item) => item.id === selectedInteraction)
						?.computation_present
				) {
					const updateDisplay = await fetchDocData(
						`solar_energy_computations/${updateId}/results`,
						selectedInteraction
					);
					if (updateDisplay) {
						setDisplayData(updateDisplay.outputs);
					}
				} else {
					setDisplayData({});
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [selectedInteraction, updateId, loading, interactionData]);

	const formatClientInputs = (clientInputs: { [key: string]: any }, options: Option[]) => {
		if (Object.keys(clientInputs).length > 0 && options.length > 0) {
				const updatedFields = Object.entries(clientInputs).reduce(
					(acc: { [key: string]: any }, [fieldId, fieldValue]) => {
						const matchingOption = options.find(
							(option) => option.id === fieldValue
						);
						if (matchingOption) {
							acc[fieldId] = matchingOption; 
						} else {
							acc[fieldId] = fieldValue; 
						}
	
						return acc;
					},
					{}
				);
				return updatedFields;
			}
		return clientInputs;
	};

	useEffect(() => {
		const fetchData = async () => {
			if (
				interactionData.some(
					(interaction) => !interaction.computation_present
				) &&
				!loading
			) {
				setLoading(true);
				try {
					const allDataFields: any = {};
					const allData = generalData[0];

					for (const key in allData.fields) {
						if (allData.fields.hasOwnProperty(key)) {
							const fieldValue = allData.fields[key];
							try {
								const docData = await fetchDocData(`client_inputs`, key);
								if (docData) {
									allDataFields[docData.name_clean] = fieldValue;
									if (docData.options.length > 0) {
									}
								} else {
									console.log(`Document with key ${key} not found.`);
								}
							} catch (error) {
								console.error(
									`Error fetching document with key ${key}:`,
									error
								);
							}
						}
					}
					const allOptions = await getAllOptions();
					await handleInteractionsParallel(interactionData, allDataFields, allOptions);
					console.log("All interactions have been processed.");
				} catch (error) {
					console.error("An error occurred during the processing:", error);
				} finally {
					setLoading(false);
				}
			}
		};
		if (loading === false && interactionData.length > 0) {
			fetchData();
		}
	}, [updateId, interactionData]);

	async function handleInteractionsParallel(
		interactionData: any,
		allDataFields: any,
		allOptions: Option[]
	) {
		const interactionPromises = interactionData
			.filter((interaction: any) => !interaction.computation_present)
			.map(async (interaction: any) => {
				const requestPayload = {
					client_inputs: {...allDataFields},
					costs: interaction.costs,
					facts: [],
					simultaneous: interaction.simultaneous,
					production: interaction.production,
				};

				for (const key in interaction.fields) {
					if (interaction.fields.hasOwnProperty(key)) {
						const fieldValue = interaction.fields[key];
						try {
							const docData = template[key]; // Assuming template is available in the scope
							requestPayload.client_inputs[docData.name_clean] = fieldValue;
							if (docData.options.length > 0) {
								}
						} catch (error) {
							const docData = await fetchDocData("client_inputs", key);
							requestPayload.client_inputs[docData.name_clean] = fieldValue;
						}
					}
				}

				// Perform operations required before the functionCallResult in sequence
				await removeDoc(
					`solar_energy_computations/${updateId}/results`,
					interaction.id
				);
				await mergeDoc(
					`solar_energy_computations/${updateId}/general_inputs`,
					"all",
					{report_ready: false}
				);
				requestPayload.client_inputs = formatClientInputs(requestPayload.client_inputs, allOptions);
				requestPayload.client_inputs["mode"] = "buca";
				requestPayload.client_inputs["subject"] = "investor";

				// Then call the main function
				return await functionCallResult(
					updateId,
					interaction.id,
					requestPayload
				);
			});

		const results = await Promise.allSettled(interactionPromises);
		results.forEach((result, index) => {
			if (result.status === "fulfilled") {
				console.log(`Interaction ${index + 1} succeeded:`, result.value);
			} else {
				console.error(`Interaction ${index + 1} failed:`, result.reason);
			}
		});
	}

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				minWidth: "60vw",
			}}
		>
			{!(displayData && Object.keys(displayData).length > 0) &&
			loading === true ? ( // Render loading spinner if loading is true
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "150px",
						}}
					>
						{t("computationLoading")}
					</h6>
					<CircularProgress />
				</div>
			) : (
				displayData &&
				Object.keys(displayData).length > 0 &&
				displayData.financial && (
					<FinancialOverview
						displayData={displayData}
						computationType="solar_energy_computations"
					/>
				)
			)}
		</div>
	);
};

export default SolarEnergyResultSummary;
