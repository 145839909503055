import React, { useState } from "react";
import {CircularProgress, Box} from "@mui/material";
import TableComponent from "../../components/utils/renderTable";
import { useTranslation } from "react-i18next";

interface EstimateData {
	url: string;
	reference: string;
	total_price_incl_tax: string;
	state: string;
	due_date: string;
	accepted_at: string | null;
}

interface EstimateDataXero {
	QuoteID: string;
	Reference: string;
	DueDate: string;
	ExpiryDate: string;
	Status: string;
	Amount: string | number;
	url: string;
  }

interface EstimateTableProps {
	searching: boolean;
	searchingXero: boolean;
	estimates: EstimateData[];
	estimatesXero: EstimateDataXero[];
	isUK: boolean;
}

const EstimateTable: React.FC<EstimateTableProps> = ({
	searching,
	searchingXero,
	estimates,
	estimatesXero,
	isUK
}) => {
	const { t } = useTranslation();
	
	const columns = [
		{
			id: "url",
			label: "Reference",
			accessor: (estimate: EstimateData) => (
				<a href={estimate.url} target="_blank" rel="noopener noreferrer">
					{estimate.reference}
				</a>
			),
		},
		{
			id: "amount",
			label: "Amount",
			accessor: (estimate: EstimateData) => `€${estimate.total_price_incl_tax}`,
		},
		{
			id: "status",
			label: "Status",
			accessor: (estimate: EstimateData) => estimate.state,
		},
		{
			id: "dueDate",
			label: "Due Date",
			accessor: (estimate: EstimateData) =>
				new Date(estimate.due_date).toLocaleDateString(),
		},
	];

	const columnsXero = [
		{
			id: "url",
			label: "Reference",
			accessor: (estimate: EstimateDataXero) => (
				<a href={estimate.url} target="_blank" rel="noopener noreferrer">
					{estimate.Reference}
				</a>
			),
		},
		{
			id: "amount",
			label: "Amount",
			accessor: (estimate: EstimateDataXero) => `£${estimate.Amount}`,
		},
		{
			id: "status",
			label: "Status",
			accessor: (estimate: EstimateDataXero) => estimate.Status,
		},
		{
			id: "dueDate",
			label: "Due Date",
			accessor: (estimate: EstimateDataXero) => {
				if (estimate.DueDate) {
					const timestampMatch = estimate.DueDate.match(/\d+/); 
					if (timestampMatch) {
						const timestamp = timestampMatch[0];
						const date = new Date(parseInt(timestamp, 10)); 
						return date.toLocaleDateString(); 
					}
					return estimate.DueDate;
				}
				return "N/A"; 
			},
		},
	];

	return (
		<Box sx={{ borderRadius: "5px"}}>
			{searching ? (
				<div className="split_div"><CircularProgress /> <label>{t("searching_estimate")}</label></div>
			) : (

				<TableComponent
					computations={estimates}
					title={t("estimate")}
					path="estimates"
					columns={columns}
					expanded_default={true}
				/>
			)}
			{searchingXero ? (
				<div className="split_div"><CircularProgress /> <label>{t("searching_estimate")}</label></div>
			) : (
				<TableComponent
					computations={estimatesXero}
					title={t("estimates_xero")}
					path="estimates"
					columns={columnsXero}
					expanded_default={isUK}
				/>
			)}
		</Box>
	);
};

export default EstimateTable;
