import React, {useEffect, useState, useCallback} from "react";
import {fetchDocData, mergeDoc} from "../../services/firebaseFunctions";
import renderInputFields from "../utils/renderInputFields";
import {useTranslation} from "react-i18next";

interface Template {
	[key: string]: any;
}

interface FormData {
	[key: string]: any;
}

interface InputSpecProps {
	updateId: string;
	filter: string;
	templateUnfiltered: Template;
	computationType: string;
}

const InputText: React.FC<InputSpecProps> = ({
	updateId,
	filter,
	templateUnfiltered,
	computationType,
}) => {
	const [template, setTemplate] = useState<Template>({}); // Initialize template state
	const [formData, setFormData] = useState<FormData>({});
	const [loading, setLoading] = useState<boolean>(true);
	const [changeMade, setChangeMade] = useState<boolean>(false);
	const {t} = useTranslation();

	useEffect(() => {
		// Function to fetch and filter template data
		const fetchAndFilterTemplate = () => {
			try {
				// Fetch template data (assuming this function works)
				const templateData = templateUnfiltered; // Replace fetchTemplateData with your actual function
				const filteredRecords: Template = {};

				Object.keys(templateData).forEach((key) => {
					const record = templateData[key];
					if (
					  record.input_categories &&
					  Array.isArray(record.input_categories) &&
					  record.input_categories.some(
						(category: any) => category.name_clean === filter
					  )
					) {
					  filteredRecords[key] = record;
					}
				  });
				const sortedTemplateData: Template = Object.fromEntries(
					Object.entries(filteredRecords).sort(
						([aKey, a], [bKey, b]) => a.order - b.order
					)
				);
				// Set the filtered and sorted template data to the state
				setTemplate(sortedTemplateData);
			} catch (error) {
				console.error("Error fetching and filtering template data:", error);
			}
		};

		// Call the fetchAndFilterTemplate function when the component mounts
		fetchAndFilterTemplate();
	}, [templateUnfiltered, filter]);

	useEffect(() => {
		if (formData && Object.keys(formData).length > 0 && loading === false) {
			mergeDoc(`${computationType}/${updateId}/text_inputs`, "all", {
				fields: formData,
			});
			setChangeMade(true);
		}
	}, [formData, computationType, updateId, loading]);

	useEffect(() => {
		const fetchFormData = async (template: Template) => {
			try {
				const textFields = await fetchDocData(
					`${computationType}/${updateId}/text_inputs`,
					"all"
				);
				const computationDataFields = textFields?.fields || {};
				setFormData(computationDataFields);
				const filteredRecords: Template = {};
				Object.keys(template).forEach((key) => {
					const record = template[key];
					if (
						record.input_categories &&
						record.input_categories.includes(filter)
					) {
						filteredRecords[key] = record;
					}
				});
			} catch (error) {
				console.error("Error fetching computation data:", error);
				setFormData({});
			}
		};

		const fetchData = async () => {
			try {
				setLoading(true);
				await fetchFormData(template);
			} catch (error) {
				console.error("Error fetching template: ", error);
			} finally {
				setTimeout(() => {
					setLoading(false);
				}, 0);
			}
		};

		fetchData();
	}, [updateId, template, filter, computationType]);

	useEffect(() => {
		if (
			changeMade === true &&
			formData &&
			Object.keys(formData).length > 0 &&
			loading === false
		) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				report_ready: false,
			});
		}
	}, [changeMade, formData]);

	const addDocument = useCallback(async () => {
		try {
			const documentData: FormData = {...formData};

			if (Object.keys(formData).length > 0) {
				const areAllFieldsFilled = Object.entries(template).every(
					([fieldId, fieldInfo]) => {
						if (
							computationType === "solar_energy_computations" &&
							!(
								fieldInfo?.conditional_field &&
								fieldInfo?.conditional_field !== "" &&
								!fieldInfo?.conditional_values.includes(
									formData[fieldInfo?.conditional_field]
								)
							)
						) {
							return !!(
								(formData[fieldId] !== null &&
									formData[fieldId] !== "" &&
									formData[fieldId] !== undefined &&
									Number.isNaN(formData[fieldId]) === false) ||
								((formData[fieldId] === "" ||
									formData[fieldId] === undefined ||
									Number.isNaN(formData[fieldId])) &&
									fieldInfo?.default_value &&
									fieldInfo.default_value !== "")
							);
						} else if (
							computationType === "energy_saving_computations" ||
							computationType === "energy_screening_computations"
						) {
							return !!(
								(formData[fieldId] !== null &&
									formData[fieldId] !== "" &&
									formData[fieldId] !== undefined &&
									Number.isNaN(formData[fieldId]) === false) ||
								((formData[fieldId] === "" ||
									formData[fieldId] === undefined ||
									Number.isNaN(formData[fieldId])) &&
									fieldInfo?.default_value &&
									fieldInfo.default_value !== "")
							);
						}

						return true;
					}
				);

				if (areAllFieldsFilled) {
					Object.entries(template).forEach(([fieldId, fieldInfo]) => {
						if (
							(formData[fieldId] === null ||
								formData[fieldId] === undefined ||
								Number.isNaN(formData[fieldId]) === true ||
								formData[fieldId] === "") &&
							fieldInfo?.default_value &&
							fieldInfo.default_value !== ""
						) {
							// Determine the default value based on data type
							let defaultValue;
							if (fieldInfo.data_type === "BOOLEAN") {
								defaultValue =
									fieldInfo.default_value === "true" ? true : false;
							} else if (
								["INTEGER", "DECIMAL", "PERCENTAGE"].includes(
									fieldInfo.data_type
								)
							) {
								defaultValue = Number(fieldInfo.default_value);
							} else {
								defaultValue = fieldInfo.default_value;
							}
							if (defaultValue !== undefined) {
								formData[fieldId] = defaultValue;
							}
						}
						if (!(fieldId in formData)) {
							documentData[fieldId] =
								fieldInfo.data_type === "BOOLEAN" ? false : "";
						} else if (
							["INTEGER", "DECIMAL", "PERCENTAGE"].includes(fieldInfo.data_type)
						) {
							documentData[fieldId] = Number(formData[fieldId]);
						} else {
							documentData[fieldId] = formData[fieldId];
						}
					});

					mergeDoc(`${computationType}/${updateId}/text_inputs`, "all", {
						fields: documentData,
					});
				}
			}
		} catch (error) {
			console.error("Error adding document: ", error);
		}
	}, [formData, template, updateId, computationType]);

	useEffect(() => {
		const handleKeyPress = (e: KeyboardEvent) => {
			if (e.key === "Enter") {
				addDocument();
			}
		};

		window.addEventListener("keydown", handleKeyPress);

		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, [addDocument]);

	return (
		<div>
			{loading ? (
				<p>Loading...</p>
			) : (
				<div>
					<div
						style={{
							position: "relative",
						}}
					>
						<>
							<div>
								{renderInputFields({
									template: template,
									formData: formData,
									setFormData: setFormData,
									setChange: setChangeMade,
									t: t,
									filter: filter,
								})}
							</div>
						</>
					</div>
				</div>
			)}
		</div>
	);
};

export default InputText;
