export const options = {
	uk: "CfxixpppdNNUyzNyyYBL",
	nl: "J4REroTAnlWa0K4D3fkJ",
	// "pv_simultaneous_percentage": "9UFkhbqlhCGpHNiteEeA",
	// 'rc_facade_current',
	// 'unit_heating_cv_current',
	// 'unit_water_electric_boiler',
	// 'rc_facade_desired',
	// 'solar_computation',
	// 'general_room_name',
	// 'Sector category',
	// 'general_name_adviseur',

	// 'pv_sde_type',
	// 'cvir_reduction_degree',
	// 'ir_isolation_lvl',
	// 'rc_roof_current',
	// 'rc_facade_desired',
	// 'unit_isolation_floor',
	// 'rc_floor_current',
	// 'general_postal_code',
	// 'unit_ventilation_wtw',
	// 'ir_watt_type',
	// 'pv_sde_type': "URamJNvx8KTJfFnCihxD",
	// 'building_category',
	// 'unit_heating_underfloor',
	// 'rc_roof_desired',
	// 'window_type',
	// 'unit_lighting_fitting_change',
	// 'pv_simultaneous_input': "XygNtUDvVNRMT1AnTC3A",
	// 'general_date_visit': "5lC7RUNxnuMuLCfIDFPr",
	// 'unit_isolation_facade',
	// 'unit_heating_infra',
	// 'rc_floor_desired',
	// 'water_m3',
	// 'unit_water_heater',
	// 'building_m2',
	// 'building_year',
	// 'unit_isolation_roof',
	// 'project_name': "YrQl2PAIyd809ekKWBhD",
	// 'electricity_outlet',
	// 'private_wire_tarriff': "U1SUCQKFxvwtKwjrKXKc",
	// 'pv_sde_value',
	// 'pv_simultaneous_days':"lVruS850hFiEjf2vAHao",
	// 'new_window_type',
	// 'height_m',
	// 'unit_heating_pump_advise'
};
