import React, {useEffect, useState, useCallback} from "react";
import {useTranslation} from "react-i18next";
import {styles} from "../utils/Styles";
import renderInputFields from "../utils/renderInputFields";
import {mergeDoc} from "../../services/firebaseFunctions";
import {crmDetails} from "../../types";

const GeneralInputPipedrive: React.FC<{
	updateId: string;
	crmDetails: crmDetails;
	computationType: string;
	formData: Record<string, any>;
	setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
	generalData: any;
	roomId: string;
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	template: Record<string, any>;
}> = ({
	updateId,
	crmDetails,
	computationType,
	formData,
	setFormData,
	template,
}) => {
	const {t} = useTranslation();
	const [change, setChange] = useState(false);

	const updateFormWithCRMDetails = useCallback(async () => {
		setFormData((prevData) => ({
		  ...prevData,
		  UY3UR611KOx9pSjxNlu6: crmDetails?.organization?.city || "",
		  YfRokLRi9yJOY7TMKo0k: crmDetails?.organization?.name || "",
		  sFPN0DboUTDB8oJr90St: crmDetails?.organization?.street || "",
		  "0scJJYOAMFTyTltNXtNL": crmDetails?.organization?.street_number || "",
		  sfTawMuK26GQXWtfjLcv: crmDetails?.person?.email || "",
		  dq6eFy6WMh75LGoOTXDy: crmDetails?.person?.first_name || "",
		  s9wXjYI6ZzLfEgmy4Ow5: crmDetails?.person?.last_name || "",
		  "5xrjtBgD8t4DQsKCjOwW": crmDetails?.organization?.id || "",
		}));
	  }, [crmDetails, setFormData]); // crmDetails is a dependency as it's used within the function
	  
	useEffect(() => {
		const someFieldsNullOrEmpty =
			formData?.["UY3UR611KOx9pSjxNlu6"] === undefined ||
			formData?.["UY3UR611KOx9pSjxNlu6"] === "" ||
			formData?.["YfRokLRi9yJOY7TMKo0k"] === undefined ||
			formData?.["YfRokLRi9yJOY7TMKo0k"] === "" ||
			formData?.["sFPN0DboUTDB8oJr90St"] === undefined ||
			formData?.["sFPN0DboUTDB8oJr90St"] === "" ||
			formData?.["sfTawMuK26GQXWtfjLcv"] === undefined ||
			formData?.["sfTawMuK26GQXWtfjLcv"] === "" ||
			formData?.["dq6eFy6WMh75LGoOTXDy"] === undefined ||
			formData?.["dq6eFy6WMh75LGoOTXDy"] === "" ||
			formData?.["s9wXjYI6ZzLfEgmy4Ow5"] === undefined ||
			formData?.["s9wXjYI6ZzLfEgmy4Ow5"] === "";

		if (someFieldsNullOrEmpty) {
			if (crmDetails?.organization) {
				updateFormWithCRMDetails();
			}
		}
	}, [crmDetails, updateFormWithCRMDetails]);

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				fields: formData,
				report_ready: false,
			});
			setChange(false);
		}
	}, [change, formData, computationType, updateId]);

	return (
		<div
			style={{
				display: "flex",
				backgroundColor: "#f0f0f0",
				height: "calc(100vh)",
			}}
		>
			<div
				style={{
					...styles.rightPanelStyle,
					minHeight: "90vh",
					maxHeight: "90vh",
					minWidth: "300px",
					position: "relative",
				}}
			>
				<div>
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{t("contactDetails")}
					</h3>
					{renderInputFields({
						template: template,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: "contact_data",
					})}
					<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
						{t("companyDetails")}
					</h3>
					{renderInputFields({
						template: template,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: "company_data",
					})}
				</div>
			</div>
		</div>
	);
};

export default GeneralInputPipedrive;
