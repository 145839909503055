import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {styles} from "../utils/Styles";
import renderInputFields from "../utils/renderInputFields";
import {mergeDoc} from "../../services/firebaseFunctions";
import {ClientInput} from "../../types";

const GeneralProjectInput: React.FC<{
	updateId: string;
	formData: Record<string, any>;
	setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>;
	roomId: string;
	generalData: any[];
	setRoomId: React.Dispatch<React.SetStateAction<string>>;
	template: Record<string, any>;
	computationType: string;
	filter?: string;
}> = ({
	updateId,
	formData,
	setFormData,
	generalData,
	template,
	computationType,
	filter,
}) => {
	const {t} = useTranslation();
	const [change, setChange] = useState(false);

	useEffect(() => {
		if (updateId && Object.keys(template).length > 0) {
			let filteredRecords: Record<string, ClientInput> = {};

			Object.keys(template).forEach((key) => {
				const record = template[key];
				if (
					record.input_categories &&
					record?.input_categories
						.map((item: any) => item.name_clean)
						.includes(filter)
				) {
					filteredRecords[key] = record;
				}
			});
		}
	}, [updateId, template, generalData, filter]);

	useEffect(() => {
		if (change === true && Object.keys(formData).length > 0) {
			mergeDoc(`${computationType}/${updateId}/general_inputs`, "all", {
				fields: formData,
				report_ready: false,
			});
			setChange(false);
		}
	}, [change, formData, computationType, updateId]);

	return (
		<div
			style={{
				display: "flex",
				backgroundColor: "#f0f0f0",
			}}
		>
			<div
				style={{
					...styles.rightPanelStyle,
					minHeight: "90vh",
					maxHeight: "90vh",
					minWidth: "300px",
					position: "relative",
				}}
			>
				<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
					{t("projectData")}
				</h3>
				{template &&
					renderInputFields({
						template: template,
						formData: formData,
						setFormData: setFormData,
						setChange: setChange,
						t: t,
						filter: filter,
					})}
			</div>
		</div>
	);
};

export default GeneralProjectInput;
