import React, {useState } from "react";
import {getAuth, signInWithEmailLink, updatePassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import "../login/login.css";
const typescriptLogo = require("../../assets/images/typescript-logo-240.png");

const SignInPage = () => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	// const [user, setUser] = useState<any | null>(null);
	const [password, setPassword] = useState("");
	// const [isPasswordSet, setIsPasswordSet] = useState(false);
	
	const handlePasswordSetup = async () => {
		try {
			if (!(password === "") && !(email === "") && password.length >= 6) {
				console.log("email", email);
				// Complete the sign-in process
				const auth = getAuth();
				signInWithEmailLink(auth, email, window.location.href)
					.then((userCredential) => {
						// Sign-in successful, you can access the userCredential here
						const user = userCredential.user;
						// setUser(user);
						console.log("user", user);

						// Update the user's password
						updatePassword(user, password)
							.then(() => {
								navigate("/home");
								window.location.reload();
								console.log("User signed in:", user);
							})
							.catch((error) => {
								// Error handling for password update
								console.error("Error updating password:", error);
							});
					})
					.catch((error) => {
						// Error handling for sign-in with email link
						console.error("Error signing in with email link:", error);
					});
			} else {
				console.log("Password must be at least 6 characters long");
			}
		} catch (error) {
			//show error on the screen
			console.error("Error setting password:", error);
		}
	};

	// const handleSignIn = () => {
	// 	// Redirect user to authenticated route after sign-in
	// 	window.location.href = "/home";
	// };

	return (
		<div
			className="container"
			style={{
				backgroundImage: `url(https://praeter.nl/wp-content/uploads/2024/02/2023_ENERGIEKEREGIO_STILL-24-scaled.jpg)`,
			}}
		>
			<div>
				<div
					className="subcontainer"
					style={{
						backgroundColor: "white",
						borderRadius: "20px",
						minWidth: "20vw",
						minHeight: "36vh",
						alignItems: "center",
					}}
				>
					<img
						src={typescriptLogo}
						alt="Logo"
						className="logo"
						style={{maxWidth: "10vw", alignItems: "center"}}
					/>
					<div>
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="inputField"
							placeholder="Confirm email address"
						/>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="inputField"
							placeholder="Enter your password"
						/>
						<button onClick={handlePasswordSetup} className="signupButton">
							Set Password
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
