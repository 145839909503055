export const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%', 
    backgroundColor: "#f0f0f0",
  },
  heading: {
		fontSize: "20px",
		fontWeight: "bold",
		marginBottom: "10px",
		fontFamily: "Basic",
	},
  signupButton: {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
  },
  deleteButton: {
    marginTop: '10px',
    marginLeft: '10px',
    padding: '10px 20px',
    backgroundColor: '#8B0000',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  loginButton: {
    backgroundColor: '#008CBA',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
  },
  logoutButton: {
    backgroundColor: '#f44336',
    color: 'white',
    padding: '10px 20px',
    margin: '10px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '5px',
  },
  rightPanelStyle: {
    fontSize: '0.7rem',
    backgroundColor: 'white',
    fontFamily: 'Basic',
    color: '#595959',
    flex: 1,
    padding: '20px',
    borderRadius: '5px',
    overflow: 'auto',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
    },
  leftPanelStyle: {
    width: 'calc(100vh / 4)',
    marginRight: '20px',
  },
  errorMessage: {
    color: 'red',
    margin: '10px 0',
  },
  logo: {
    maxWidth: '20%',
    marginTop: '100px',
    marginBottom: '100px',
  },
  body: {
    backgroundColor: '#f3f7f7',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    marginLeft: '20px',
  },
  inputWrapper: {
    marginBottom: '10px',
  },
  label: {
    fontSize: '14px',
    marginBottom: '8px',
    color: '#555',
    fontFamily: 'Karla',
    textTransform: 'uppercase',
  },
  labelLower: {
    fontSize: '14px',
    marginBottom: '8px',
    color: '#555',
    fontFamily: 'Karla',
  },
  input: {
    fontSize: '16px',
    fontFamily: 'Karla',
    width: '100%',
    border: '1px solid #ccc',
    padding: '5px',
    borderRadius: '3px',
    marginBottom: '8px',
  },
  summaryValue: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '5px',
    borderRadius: '3px',
    marginBottom: '8px',
    fontFamily: 'Karla',
    textTransform: 'uppercase',
    fontWeight: 'bolder',
    fontSize: '14px',
    color: '#267FB8',
    backgroundColor: 'white',
  },
  summaryValueLower: {
    width: '100%',
    border: '1px solid #ccc',
    padding: '5px',
    borderRadius: '3px',
    marginBottom: '8px',
    fontFamily: 'Karla',
    fontWeight: 'bolder',
    fontSize: '14px',
    color: '#267FB8',
    backgroundColor: 'white',
  },
  updateButton: {
    fontFamily: 'Karla',
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  DealSelector: {
    height: '100vh',
    minWidth: 'auto',
    width: '30px',
    padding: '6px 8px',
    fontSize: '0.7rem',
    textTransform: 'none',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    backgroundColor: '#595959',
    fontFamily: 'Basic',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
  },
};
