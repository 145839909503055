import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import InputSpec from "../../components/computation-input-specific/InputSpecific";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";

interface Interaction {
	id: string;
	fields: {
		fbzu2wmWI3MdG2BcWnIs: string;
	};
	production?: any; // Update the type according to your data structure
}

interface Template {
	[key: string]: any;
}

interface Props {
	updateId: string;
	selectedInteraction: string;
	interactionData: Interaction[];
	pageReady: boolean;
	template: Template;
}

const SolarEnergyComputationSpecs: React.FC<Props> = ({
	updateId,
	selectedInteraction,
	interactionData,
	pageReady,
	template,
}) => {
	const {t} = useTranslation();

	useEffect(() => {
		if (interactionData) {
			let status = false;
			if (interactionData.length > 0) {
				// status = areAllFieldsFilled(template, interactionData[0]?.fields);
				status = true; // Change this to your logic
			}
			if (!(status === pageReady)) {
				mergeDoc("solar_energy_computations", updateId, {
					progress: {
						2: {
							done: status,
							name: "Computation specs", // Translation key for "Computation specs"
						},
					},
				});
			}
		}
	}, [template, interactionData, updateId, pageReady, t]);

	return (
		<div style={{...styles.container, marginLeft: "20px"}}>
			{/* Left Panel */}
			{selectedInteraction !== "" && Object.keys(template).length > 0 && (
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "-10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("solarPanelConstruction")}{" "}
							{/* Translation key for "Specificaties zonnepanelen" */}
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							templateUnfiltered={template}
							filter={"specifications_solarpanels"} // Original filter name
							computationType={"solar_energy_computations"}
						/>
					</div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("tariffs")} {/* Translation key for "Tarieven" */}
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							templateUnfiltered={template}
							filter={"rates"} // Original filter name
							computationType={"solar_energy_computations"}
						/>
					</div>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minWidth: "250px",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							{t("financialData")}{" "}
							{/* Translation key for "Financiële gegevens" */}
						</h3>
						<InputSpec
							updateId={updateId}
							interactionId={selectedInteraction}
							interactionData={interactionData}
							templateUnfiltered={template}
							filter={"financial_data"} // Original filter name
							computationType={"solar_energy_computations"}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default SolarEnergyComputationSpecs;
