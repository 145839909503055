// promiseWithResolversPolyfill.ts

declare global {
  interface PromiseConstructor {
    withResolvers<T>(): { promise: Promise<T>, resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void };
  }
}

if (typeof Promise.withResolvers !== 'function') {
  Promise.withResolvers = function<T>() {
    let resolve: (value: T | PromiseLike<T>) => void;
    let reject: (reason?: any) => void;

    const promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    // Use type assertions to assure TypeScript that `resolve` and `reject` are assigned
    return {
      promise,
      resolve: resolve!, // Type assertion here
      reject: reject! // Type assertion here
    };
  };
}

export {}; // To make this file a module and avoid global scope pollution
