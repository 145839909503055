import React, { useState, useEffect } from "react";
import Main from "../../layout/main/Main";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom";
import { fetchCRMDetails } from "../../services/apiFunctions";
import { styles } from "../../components/utils/Styles";
import DealFinder from "../../components/layout/deal-finder";
import useFetchCRMData from "../../hooks/useFetchCRMData";

interface CrmData {
	postalCode: string;
	streetNumber: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	notes: string;
	orgName: string;
}

const Elabelpage: React.FC = () => {
	const [url, setUrl] = useState<string>("https://bestendigvastgoed.nl/?p=bestendigvastgoed-form&est_link=66c8b32d6449d");
	const { t } = useTranslation();
	const { dealId, selectedPageName } = useParams();
	const computationType = "energy_screening_computations";
	const [clientId, setClientId] = useState<string>(dealId || "");
	const [showScreens, setShowScreens] = useState<boolean>(false);
	const [crmDetails, setCRMDetails] = useFetchCRMData(clientId);
	const [urlData, setUrlData] = useState<CrmData>({
		postalCode: "",
		streetNumber: "",
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		notes: "",
		orgName: "",
	});

	useEffect(() => {
		if (urlData.firstName.length > 0) {
			setUrl(`https://bestendigvastgoed.nl/?p=bestendigvastgoed-form&est_link=66c8b32d6449d&postcode=${urlData.postalCode}&huisnummer=${urlData.streetNumber}&voornaam=${urlData.firstName}&achternaam=${urlData.lastName}&email=${urlData.email}&telefoon=${urlData.phoneNumber}&opmerkingen=${urlData.notes}&bedrijfsnaam=${urlData.orgName}`)
		}
	}, [urlData, crmDetails])

	return (
		<Main>
			<div
				style={{
					backgroundColor: "#F4A32D",
					width: "100vw",
					padding: "10px",
					height: "10px",
				}}
			>
				<h2
					style={{
						textAlign: "center",
						marginTop: -9,
						color: "white",
						fontSize: "18px",
						fontFamily: "basic",
						fontWeight: "bold",
					}}
				>
					{t("ElabelQuote")}
				</h2>
			</div>
			<div style={styles.container}>
				<div style={{ display: "flex" }}>
					<div style={{ maxWidth: "250px" }}>
						<DealFinder
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
							urlData={urlData}
						/>
					</div>

					<iframe
						src={url}
						title="Bestendig Vastgoed Energielabel Tool"
						frameBorder="0"
						style={{ minWidth: '70vw', height: '100vh' }} // Adjust width and height as needed
					></iframe>
				</div>
			</div>
			{/* <Container style={{  minWidth: "100vw", justifyContent: "flex-start" }}>
				<div style={{display: "flex"}}>
					<div style={{ maxWidth: "250px"}}>
						<DealFinder
							clientId={clientId}
							setClientId={setClientId}
							setShowScreens={setShowScreens}
							crmDetails={crmDetails}
							computationType={computationType}
							urlData={urlData}
						/>
                </div>

			<iframe
                src= {url}
                title="Bestendig Vastgoed Energielabel Tool"
                frameBorder="0"
                style={{ width: '100vw', height: '100vh' }} // Adjust width and height as needed
            ></iframe>
			</div>
            </Container> */}
		</Main>
	);
};

export default Elabelpage;
