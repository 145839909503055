import React, {useState, useEffect, useCallback} from "react";
import {TextField, List, ListItem, ListItemText} from "@mui/material";
import {styles} from "../../utils/Styles";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {fetchDocsData} from "../../../services/firebaseFunctions";
import {addDocumentComputation} from "../../utils/computationInitialization";
import {useTranslation} from "react-i18next";
import _ from "lodash";

interface CrmData {
	postalCode: string;
	streetNumber: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	notes: string;
	orgName: string;
}

interface DealFinder {
	clientId: string;
	setClientId: React.Dispatch<React.SetStateAction<string>>;
	setShowScreens: React.Dispatch<React.SetStateAction<boolean>>;
	crmDetails?: any;
	computationType: string;
	urlData: CrmData;
}

interface ClientInfoprops {

	clientInfo: any;
	clientId: string;
	t: any;

}

const ClientInfo: React.FC<ClientInfoprops> = ({
	clientInfo,
	clientId,
	t,


}) => {
	if (!clientInfo || clientId !== clientInfo.requested_deal) {
		return null;
	}

	const {organization, person, pipeline,deals, stage} = clientInfo;

	const fields = [
		{label: "Postcode", value: organization?.postal_code},
		{label: "Huisnummer", value: organization?.street_number},
		{label: "clientNumber", value: organization?.id},
		{label: "organization", value: organization?.name},
		{label: "Firstname",value: person?.first_name,},
		{label: "Lastname",value: person?.last_name,},
		{label: "email", value: person?.email},
		{label:"Telefoon", value: person?.phone}	
	];

	return (
		<div
			style={{
				fontFamily: "Basic",
				lineHeight: "1.25",
				marginBottom: "10px",
				color: styles.DealSelector.color,
				maxWidth: "250px",
				wordWrap: "break-word",
				fontSize: "14px",
			}}
		>
			{fields.map((field, index) => (
				<div key={index}>
					<strong>{t(field.label)}</strong>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "flex-start",
						}}
					>
						<span style={{textAlign: "right"}}>{field.value}</span>
					</div>
				</div>
			))}
		</div>
	);
};

interface Computation {
	id: string;
}

const DealFinder: React.FC<DealFinder> = ({
	clientId,
	setClientId,
	setShowScreens,
	crmDetails,
	computationType,
	urlData
	
}) => {
	const {t} = useTranslation();
	const [showSearchBar, setShowSearchBar] = useState(false);
	const [computationsData, setComputationsData] = useState<Computation[]>([]);
	const [clientIdInput, setClientIdInput] = useState(clientId || "");

	const delayedSetClientId = useCallback(
		_.debounce((value: string) => {
			setClientId(value);
			//   setShowScreens(true);
		}, 500), // Adjust delay as needed
		[]
	);
	const handleClientIdChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setShowScreens(false);
		const regex = /^(ER|PR)_\d+$/;
		setClientIdInput(event.target.value);

		if (regex.test(event.target.value)) {
			delayedSetClientId(event.target.value);
		} else {
			setClientId("");
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const computationUpdate: any = await fetchDocsData(computationType);
			setComputationsData(computationUpdate);
		};

		fetchData();
	}, [computationType]);


	useEffect(() => {
		
		if(crmDetails && crmDetails.organization && crmDetails.person && crmDetails.requested_deal && crmDetails.requested_deal === clientId){
			const orgData = crmDetails.organization
			const personData = crmDetails.person

			const cleanValue = (value: string) => value ? value.replace(/\s+/g, '') : '';

			urlData.postalCode = cleanValue(orgData.postal_code);
			urlData.streetNumber = cleanValue(orgData.street_number);
			urlData.orgName = cleanValue(orgData.name);
			urlData.firstName = cleanValue(personData.first_name);
			urlData.lastName = cleanValue(personData.last_name);
			urlData.email = cleanValue(personData.email);
			urlData.phoneNumber = cleanValue(personData.phone);
			urlData.notes = cleanValue(crmDetails.requested_deal);
		}
	},[crmDetails])

	return (
		<div
			style={{
				padding: "20px",
				borderRadius: "5px",
				minHeight: "40vh",
				backgroundColor: styles.DealSelector.backgroundColor,
				minWidth: "200px",
				color: styles.DealSelector.color,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
			}}
		>
			<TextField
				label={t("computationId")}
				value={clientIdInput}
				onChange={handleClientIdChange}
				variant="standard"
				margin="normal"
				fullWidth
				InputProps={{
					style: {
						backgroundColor: "#fff",
						borderRadius: "3px",
						padding: "10px",
						fontFamily: "Karla",
						borderColor: "blue",
						position: "relative",
					},
				}}
				InputLabelProps={{
					style: {
						color: clientIdInput ? "white": "black",
						fontWeight: clientIdInput ? "bold": "normal",
						transform: clientIdInput ? "translate(0, -10px) scale(0.8)" : "translate(10px, 30px) scale(1)", 
						fontFamily: "Karla",
						position: "absolute",
						zIndex: 1,
					},
				}}
			/>

			<div style={{display: "flex", justifyContent: "flex-end"}}>
				<div style={{display: "flex", alignItems: "center"}}>
					
				</div>
			</div>
			{crmDetails !== undefined && crmDetails && !showSearchBar && (
				<ClientInfo clientId={clientId} clientInfo={crmDetails} t={t} />
			)}
			
			
		</div>
	);
};

export default DealFinder;
