import React, {useState, useEffect } from "react"; // Import ChangeEvent
import {styled} from "@mui/system";
import Select from "react-select";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	TextField,
	IconButton,
} from "@mui/material";
import {queryCollection} from "../../services/firebaseFunctions";
import {useTranslation} from "react-i18next";
import {Cost, SearchOption} from "../../types";

const debounce = (func: Function, delay: number) => {
	let timerId: NodeJS.Timeout;
	return (...args: any[]) => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

const delayedSetChange = debounce(
	(setAction: React.Dispatch<React.SetStateAction<boolean>>) => {
		setAction(true);
	},
	500
); // Adjust the delay as needed

const StyledTable = styled(Table)({
	maxWidth: 250,
});

const StyledTableCell = styled(TableCell)({
	maxWidth: "100px",
	fontsize: "14px",
	fontFamily: "Karla",
	padding: "0px",
});

const StyledInput = styled(TextField)({
	minWidth: "50px",
	"& input": {
		padding: "2px",
		fontSize: "12px",
		fontFamily: "Karla",
		paddingTop: "5px",
		paddingBottom: "5px",
		height: "auto",
		whiteSpace: "break-spaces", // Allow breaking long words
	},
	marginTop: "2px",
});

const CostsEditableTable: React.FC<{
	context: any;
	submitted: boolean;
	setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
	data: Cost[];
	setData: React.Dispatch<React.SetStateAction<any>>;
	type: string;
	editMode: boolean;
	setAction: React.Dispatch<React.SetStateAction<boolean>>;
	kwpValue: number;
	userType: string;
}> = ({context, data, setData, type, editMode, setAction, kwpValue}) => {
	const {t} = useTranslation();
	const [options, setOptions] = useState<Cost[]>([]);
	const [selectedOption, setSelectedOption] = useState<SearchOption | null>(
		null
	);
	const [SearchOptions, setSearchOptions] = useState([]);
	const panel_num: number | undefined = context?.total_pv;

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const fetchedOptions = await queryCollection("costs", {
					frequency: type,
					pv: true,
				});
				// Filter out null values
				const options = fetchedOptions.filter(
					(option: any) =>
						option !== null &&
						((option.kwp &&
							option.kwp.kwp_start &&
							kwpValue / 1000 >= option.kwp.kwp_start &&
							kwpValue / 1000 < option.kwp.kwp_end) ||
							!option?.kwp)
				) as Cost[];
				setOptions(options);
				const separateOptions = options.map((option) => ({
					label: option.name,
					value: option.id,
				}));
				setSearchOptions(separateOptions as any);
			} catch (error) {
				console.error("Error fetching options:", error);
			}
		};
		if (type) {
			fetchOptions();
		}
	}, [type, kwpValue]);

	const handleInputChange = (value: string, index: string, key: keyof Cost) => {
		const newData: Record<string, any>[] = [...data];
		if (key === "name") {
			newData[index as any][key as any] = value;
			newData[index as any]["name_clean"] = value.replace(/\s/g, "_");
			newData[index as any]["id"] = value.toLowerCase().replace(/\s/g, "_");
		} else if (key === "value") {
			newData[index as any][key as any] = Number(value);
		}
		newData[index as any]["frequency"] = type;
		setData(newData);
		delayedSetChange(setAction);
	};

	const handleAddRow = () => {
		setData([...data, {name: "", value: 0}]);
		delayedSetChange(setAction);
	};

	const handleDeleteRow = (index: number) => {
		const newData = [...data];
		newData.splice(index, 1);
		setData(newData);
		delayedSetChange(setAction);
	};

	useEffect(() => {
		// Update the selected option state
		if (options.length > 0 && selectedOption) {
			const selectedCost = options.find(
				(option) => option.id === selectedOption.value
			);

			const updatedOption: Cost = {
				frequency: type,
				name: selectedCost?.name || "",
				name_clean: selectedCost?.name_clean || "",
				value: 0,
				id: selectedCost?.id || "",
			};
			if (
				selectedCost != null &&
				panel_num != null &&
				selectedCost?.fixed != null &&
				selectedCost?.variable != null &&
				selectedCost.kwp?.kwp_start != null
			) {
				const value = selectedCost.fixed + kwpValue * selectedCost.variable;
				updatedOption.value = value;
				// Set the new data array to the state
				setData((prevData: Cost[]) => [...prevData, updatedOption]);
				setSelectedOption(null);
			} else if (
				selectedCost != null &&
				selectedCost != null &&
				panel_num != null &&
				selectedCost?.fixed != null &&
				selectedCost?.variable != null
				) {
				
				const value = selectedCost.fixed + panel_num * selectedCost.variable;
				updatedOption.value = value;
				// Set the new data array to the state
				setData((prevData: Cost[]) => [...prevData, updatedOption]);
				setSelectedOption(null);
			}
		}
	}, [selectedOption, options,kwpValue, panel_num, type, setData]);

	return (
		<TableContainer
			component={Paper}
			style={{
				minWidth: "250px",
				maxWidth: "250px",
				minHeight: "75vh",
				boxShadow: "none",
			}}
		>
			{
				<>
					<Select
						options={SearchOptions}
						onChange={(option) => {
							setSelectedOption(option);
							delayedSetChange(setAction);
						}}
					/>

					<StyledTable aria-label="editable table">
						<TableHead>
							<TableRow>
								<StyledTableCell style={{width: "60%"}}>Name</StyledTableCell>
								<StyledTableCell
									align="right"
									style={{width: "30%", marginLeft: "-20px"}}
								>
									{t("valueCurrency")}
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((record, index) => (
								<TableRow key={index}>
									<StyledTableCell component="th" scope="row">
										<StyledInput
											type="string"
											value={record.name || ""}
											onChange={(event) =>
												handleInputChange(
													event.target.value,
													String(index),
													"name"
												)
											}
										/>
									</StyledTableCell>
									{editMode ? (
										<StyledTableCell align="right">
											<StyledInput
												type="number"
												value={Math.round(record.value) || ""}
												onChange={(event) =>
													handleInputChange(
														event.target.value,
														String(index),
														"value"
													)
												}
											/>
										</StyledTableCell>
									) : (
										<StyledTableCell align="right">
											{record.value || ""}
										</StyledTableCell>
									)}
									<StyledTableCell>
										<IconButton
											onClick={() => handleDeleteRow(index)}
											style={{fontSize: "12px"}}
										>
											X
										</IconButton>
									</StyledTableCell>
								</TableRow>
							))}
						</TableBody>
					</StyledTable>
					<IconButton
						onClick={handleAddRow}
						style={{fontSize: "16px"}}
						aria-label="add"
					>
						+
					</IconButton>
				</>
			}
		</TableContainer>
	);
};

export default CostsEditableTable;
