import React, { useState, useEffect } from "react";
import { Container, Box, Typography, CircularProgress} from "@mui/material";
import { fetchDataBigquery, fetchXeroEstimates, postGetContact } from "../../services/apiFunctions";
import Main from "../../layout/main/Main";
import {useTranslation} from "react-i18next";
import {styles} from "../../components/utils/Styles";
import ClientSelector from "../../components/layout/client-search";
import UnauthorizedPage from "../unauthorized";
import {auth} from "../../config/firebase";
import ClientSummary from "./ClientSummary";
import XeroUkCheck from "./XeroUk";
import EstimateTable from "./EstimateTable";
import {useParams, useNavigate} from "react-router-dom";
import ProductSection from "./ProductSection";
import CreateEstimateSection from "./CreateEstimate";
import { fetchDocsDataStream } from "../../services/firebaseFunctions";
import DealsSection from "./DealsSection";
import './index.css'

import {
	Paid as PaidIcon,
	Receipt as ReceiptIcon,
	CheckCircle as CheckCircleIcon,
	Error as ErrorIcon,
} from "@mui/icons-material";
import useCustomClaims from "../../hooks/useCustomClaims";
import { set } from "lodash";

interface CustomClaims {
	[key: string]: boolean;
}

interface Product {
	id: string;
	phase: string;
	name: string;
	description: string;
	quantity?: number;
	prices: {currency: string; price: number}[];
}

interface DealData {
  id: string
  deal_id: string;
  deal_rowkey: string;
  deal_title: string;
  org_id: string;
  org_rowkey: string;
}

interface EstimateData {
  url: string;
  reference: string;
  total_price_incl_tax: string;
  state: string;
  due_date: string;
  accepted_at: string | null;
  id?: string;
}
interface EstimateDataXero {
  QuoteID: string;
  Reference: string;
  DueDate: string;
  ExpiryDate: string;
  Status: string;
  Amount: string | number;
  url: string;
  id?: string;
}

const CustomerSuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const [isUk, setIsUk] = useState(false);
  const [xeroToken, setXeroToken] = useState<string | null>(null);
  const [searchingEstimates, setSearchingEstimates] = useState(false);
  const [searchingEstimatesXero, setSearchingEstimatesXero] = useState(false);
  const [searchingDeals, setSearchingDeals] = useState(false);
  const [dataResultsEstimates, setDataResultsEstimates] = useState<any[]>([]);
  const [dataResultsEstimatesXero, setDataResultsEstimatesXero] = useState<any[]>([]);
  const [clientId, setClientId] = useState(organizationId || "");
  const [oldClientId, setOldClientId] = useState("");
  const [clientData, setClientData] = useState<any[]>([]);
  const [lifetimeValue, setLifetimeValue] = useState(0);
  const [openEstimatesCount, setOpenEstimatesCount] = useState(0);
  const [searchingProducts, setSearchingProducts] = useState(false);
  const [dataResultsProducts, setDataResultsProducts] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [workflows, setWorkflows] = useState<any[]>([])
  const [estimateTexts, setEstimateTexts] = useState<any[]>([])
  const [orgDeals, setOrgDeals] = useState<any[]>([]);
  const [selectedDeal, setSelectedDeal] = useState<DealData>()
  const [estimateCreated, setEstimateCreated] = useState<boolean>(false);
  const [newContact, setNewContact] = useState<string>("");

  const customClaims = useCustomClaims();
  const navigate = useNavigate();

  useEffect(() => {
    if (clientId !== "") {
      navigate(`/customer-success/${clientId}`);
    }
  }, [clientId, navigate]);

  useEffect(() => {
		fetchDocsDataStream("estimate_workflows", setWorkflows as any);
	}, []);

  useEffect(() => {
		fetchDocsDataStream("estimate_texts", setEstimateTexts as any);
	}, []);

  useEffect(() => {
    if (clientData.length === 0) {
      setClientId("");
      setSelectedDeal({ id: "", deal_id: "", deal_rowkey: "", deal_title: "", org_id: "", org_rowkey: "" })
      setSelectedProduct(null);
      setSelectedProducts([]);
      setOrgDeals([]);
    }
    if (clientId && clientId !== oldClientId) {
      setSelectedDeal({ id: "", deal_id: "", deal_rowkey: "", deal_title: "", org_id: "", org_rowkey: "" })
      setSelectedProduct(null);
      setSelectedProducts([]);
      setOrgDeals([]);
    }
    if (clientId &&  clientData.length === 1) {
      setOldClientId(clientId);
    }
  }, [clientData, clientId])

  useEffect(() => {
    if (clientId && clientData.length === 1) {
      setSearchingEstimates(true);
      const clientRowkey = clientData[0]?.rowkey;
      fetchDataBigquery(
        "app.moneybird_estimates_contacts",
        "client_id",
        clientRowkey
      )
        .then((data: any) => {
          if (data && data.data) {
              const estimates: EstimateData[] = data.data.map((estimate: any) => {
              const parsedEstimate = JSON.parse(estimate.data);
              const urlParts = parsedEstimate.url.split('/');
              urlParts[urlParts.length - 1] = parsedEstimate.id;
              if (urlParts.length > 6) {
                urlParts.splice(5, 1);
              }
              parsedEstimate.url = urlParts.join('/');

              return parsedEstimate;
            });
            setDataResultsEstimates(estimates);

						const newLifetimeValue = estimates.reduce(
							(acc, estimate) =>
								estimate.accepted_at
									? acc + parseFloat(estimate.total_price_incl_tax)
									: acc,
							0
						);
						setLifetimeValue(newLifetimeValue);

            const newOpenEstimatesCount = estimates.filter(
              (estimate) => !estimate.accepted_at
            ).length;
            setOpenEstimatesCount(newOpenEstimatesCount);
          } else {
            setDataResultsEstimates([]);
            setLifetimeValue(0);
            setOpenEstimatesCount(0);
          }
          setSearchingEstimates(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataResultsEstimates([]);
          setLifetimeValue(0);
          setOpenEstimatesCount(0);
          setSearchingEstimates(false);
        });
    }
  }, [clientId, clientData, estimateCreated]);

  useEffect(() => {
    if (clientId && clientData.length === 1 && newContact.length > 0 && xeroToken) {
      setSearchingEstimatesXero(true);
      fetchXeroEstimates(
        newContact,
        xeroToken
      )
        .then((data: any) => {
          if (data && data.data) {
              const estimates: EstimateDataXero[] = data.data.map((estimate: any) => {
                const parsedEstimate = estimate;
                parsedEstimate.url = `https://go.xero.com/app/!tg-HS/quotes/edit/${parsedEstimate.QuoteID}`;
                parsedEstimate.id = parsedEstimate.QuoteID;
                return parsedEstimate;
            });
            setDataResultsEstimatesXero(estimates);

          } else {
            setDataResultsEstimatesXero([]);
          }
          setSearchingEstimatesXero(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataResultsEstimatesXero([]);
          setSearchingEstimatesXero(false);
        });
    }
  }, [xeroToken, newContact, estimateCreated, clientId, clientData]);

  useEffect(() => {
    if (clientId && clientData.length === 1) {
      setSearchingProducts(true);
      const clientRowkey = clientData[0]?.rowkey;
      fetchDataBigquery(
        "app.pipedrive_products",
        "rowkey",
        clientRowkey.toString().split("_")[0],
        false,
        500
      )
        .then((data: any) => {
          if (data && data.data) {
            const products: Product[] = data.data.map((product: any) => {
              const parsedData = JSON.parse(product.data);
              return {
                ...parsedData,
                phase: product.phase,
              } as Product;
            }).filter((product: Product) => {
              return (
                product.name
              );
            });
            setDataResultsProducts(products);
          } else {
            setDataResultsProducts([]);
          }
          setSearchingProducts(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataResultsProducts([]);
          setSearchingProducts(false);
        });
    }
  }, [clientId, clientData]);

  useEffect(() => {
    if (clientId && clientData.length === 1) {
      setSearchingDeals(true);
      const clientRowkey = clientData[0]?.rowkey;
      fetchDataBigquery(
        "app.pipedrive_org_deals",
        "org_rowkey",
        clientRowkey.toString()
      )
        .then((data: any) => {
          if (data && data.data[0]) {
              setOrgDeals(data.data)
          } else {
            setOrgDeals([]);
          }
          setSearchingDeals(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setSearchingDeals(false);
        });
    }
  }, [clientId, clientData]);

  const fields = [
    {
      label: "LTV",
      value: lifetimeValue > 0 ? `€${lifetimeValue.toFixed(2)}` : "-",
      icon: <PaidIcon />,
    },
    { label: "Oppty", value: "-", icon: <ReceiptIcon /> },
    {
      label: "To accept",
      value: openEstimatesCount > 0 ? openEstimatesCount : "-",
      icon: <ErrorIcon />,
    },
    { label: "To pay", value: "-", icon: <CheckCircleIcon /> },
  ];

	if (!customClaims?.["praeter-advisor"] && !customClaims?.["admin"] && !customClaims?.["sales-manager"]) {
		return <UnauthorizedPage />;
	}

  return (
    <Main>
      <Container maxWidth="md" style={{ ...styles.container, minWidth: "100vw" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <div style={{ flex: 1, marginRight: "10px" }}>
            <ClientSelector
              clientId={clientId}
              setClientId={setClientId}
              setShowScreens={() => {}}
              dataResults={clientData}
              setDataResults={setClientData as any}
            />
            <ClientSummary fields={fields} />
            <Box
              sx={{
                padding: "20px",
                marginTop: "10px",
                borderRadius: "5px",
                minHeight: "40vh",
                backgroundColor: "white",
                minWidth: "20vw",
                maxWidth: "20vw",
                color: "black",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                maxHeight: "50vh",
                position: "relative",
                marginLeft: "-25px",
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: "basic",
                  fontWeight: "bold",
                  fontSize: "16px",
                  color: styles.rightPanelStyle.color,
                  margin: 0,
                }}
              >
                {t("sel_organization")}: {clientId}
                <br />
                <br />
                <br />
                {t("sel_deal")}: {selectedDeal?.deal_rowkey}
                <br />
                <br />
                <br />
                {t("products_made")}:
              </Typography>
             
            </Box>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxHeight: "90vh",
              minWidth: "70vw",
              marginLeft: "-10px",
            }}
          >
            <XeroUkCheck 
              isUk={isUk}
              setIsUk={setIsUk}
              xeroToken={xeroToken}
              setXeroToken={setXeroToken}
            />
            <EstimateTable
              searching={searchingEstimates}
              searchingXero={searchingEstimatesXero}
              estimates={dataResultsEstimates}
              estimatesXero={dataResultsEstimatesXero}
              isUK={isUk}
            />

            {orgDeals.length > 0 && !searchingDeals? (
            <DealsSection
              deals={orgDeals}
              searching={searchingDeals}
              selectedDeal={selectedDeal}
              setSelectedDeal={setSelectedDeal}
            />
            ) : searchingDeals ? (<div className="split_div"><CircularProgress /> <label>{t("searching_deal")}</label></div>) : (<span className="not_found_text">{t("no_deals")}</span>)
            }
  
            {selectedDeal && selectedDeal.deal_title && selectedDeal.deal_title.length > 0 &&
            <ProductSection
              products={dataResultsProducts}
              loading={searchingProducts}
              selectedProduct={selectedProduct}
              onProductSelect={setSelectedProduct}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
            }

            {selectedProducts.length > 0 && selectedDeal && <CreateEstimateSection
              selectedProducts={selectedProducts}
              selectedClientData={clientData}
              workflows={workflows}
              estimateTexts={estimateTexts}
              deal={selectedDeal}
              estimateCreated={estimateCreated}
              setEstimateCreated={setEstimateCreated}
              clientId={clientId}
              isUK={isUk}
              XeroToken={xeroToken}
              newContact={newContact}
              setNewContact={setNewContact}
            />}

          </div>
        </div>
      </Container>
    </Main>
  );
};

export default CustomerSuccessPage;
