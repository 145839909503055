import React from "react";
import {Box, Grid, Typography} from "@mui/material";

import {useTranslation} from "react-i18next";
import {styles} from "../../components/utils/Styles";

interface Field {
	label: string;
	value: string | number;
	icon: JSX.Element;
}

interface ClientSummaryProps {
	fields: Field[];
}

const ClientSummary: React.FC<ClientSummaryProps> = ({fields}) => {
	const {t} = useTranslation();

	return (
		<Box
			sx={{
				...styles.rightPanelStyle,
				borderRadius: "5px",
				maxWidth: "20vw",
				backgroundColor: styles.summaryValue.color,
				color: "black",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				marginLeft: "-25px",
			}}
		>
			<Grid container spacing={2}>
				{fields.map((field, index) => (
					<Grid item xs={12} sm={6} md={3} key={index}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								padding: "5px",
								borderRadius: "5px",
								maxHeight: "10vh",
								backgroundColor: "#f9f9f9",
								boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
								textAlign: "center",
							}}
						>
							{field.icon}
							<Typography
								variant="body2"
								sx={{
									fontFamily: "karla",
									color: "#333",
									fontSize: "12px",
									marginTop: "8px",
								}}
							>
								{t(field.label)}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									fontFamily: "karla",
									color: "#555",
									marginTop: "4px",
								}}
							>
								{field.value}
							</Typography>
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default ClientSummary;
