import React from "react";
import {Bar} from "react-chartjs-2";
import {styles} from "../utils/Styles";
import type {ChartData, ChartOptions} from "chart.js";
import Chart from "chart.js/auto";
import {CategoryScale} from "chart.js";
import {useTranslation} from "react-i18next";
Chart.register(CategoryScale);

interface Record {
	month: string;
	peak: number | null;
	non_peak: number | null;
	total: number | null;
}

interface StackedBarChartWithLineProps {
	data: Record[];
	production: number;
}
const productionSimultaneous: number[] = [
	0.01980198019802, 0.03712871287129, 0.07178217821782, 0.12376237623762,
	0.14851485148515, 0.15346534653465, 0.14356435643564, 0.11881188118812,
	0.08663366336634, 0.04950495049505, 0.02722772277228, 0.01980198019802,
];

const StackedBarChartWithLine: React.FC<StackedBarChartWithLineProps> = ({
	data,
	production,
}) => {
	const {t} = useTranslation();
	const monthList = data.map((record) => t(`months.${record.month}`));
	const peakList = data.map((record) => record.peak);
	const dalList = data.map((record) => record.non_peak);
	const simultaneous = productionSimultaneous.map(
		(value) => value * production
	);

	// Combine bar and line datasets
	const chartData = {
		labels: monthList,
		datasets: [
			{
				label: t("peakConsumption"),
				backgroundColor: "rgba(54, 162, 235, 0.5)",
				data: peakList,
				stack: "stack",
				type: "bar", // Explicitly set type to bar for this dataset
			},
			{
				label: t("offPeakConsumption"),
				backgroundColor: "rgba(255, 159, 64, 0.5)",
				data: dalList,
				stack: "stack",
				type: "bar", // Explicitly set type to bar for this dataset
			},
			{
				label: t("production"),
				borderColor: "rgba(75, 192, 192, 1)",
				borderWidth: 2,
				fill: false,
				data: simultaneous,
				type: "line", // Explicitly set type to line for this dataset
			},
		],
	};

	const options: ChartOptions<"bar" | "line"> = {
		maintainAspectRatio: false,
		scales: {
			x: {
				stacked: true,
				ticks: {
					font: {
						family: styles.label.fontFamily,
					},
				},
			},
			y: {
				stacked: true,
				position: "left",
				ticks: {
					font: {
						family: styles.label.fontFamily,
					},
				},
			},
		},
		plugins: {
			legend: {
				labels: {
					font: {
						family: styles.label.fontFamily,
					},
				},
			},
		},
	};

	return (
		<div>
			<Bar
				data={chartData as ChartData<"bar">}
				options={options}
				style={{minHeight: "300px"}}
			/>
		</div>
	);
};

export default StackedBarChartWithLine;
