import {initializeIdToken} from "./idToken";

interface RequestOptions {
	method: string;
	headers: {
		"Content-Type": string;
		Authorization: string;
		accept: string;
	};
	body?: string;
}

const apiBaseUrl = 
    process.env.REACT_APP_ENV === "development" && 
    (window.location.hostname === "127.0.0.1" || 
     window.location.hostname === "localhost" || 
     window.location.hostname === "0.0.0.0")
        ? "http://localhost:8000"
        : process.env.REACT_APP_ENV === "development"
        ? "https://praeter-dash-api-s3r2dk7zkq-ez.a.run.app"
        : "https://praeter-dash-api-xsy3xzp2oa-ez.a.run.app";

const createRequestOptions = async (
	method: string,
	body: any = null
): Promise<RequestOptions> => {
	const token = await initializeIdToken();
	const headers = {
		"Content-Type": "application/json",
		Authorization: `Bearer ${token}`,
		accept: "application/json",
	};
	return body
		? {method, headers, body: JSON.stringify(body)}
		: {method, headers};
};

const fetchCRMDetails = async (deal_id: string, org_id: string) => {
	const requestOptions = await createRequestOptions("GET");
	const response = await fetch(
		`${apiBaseUrl}/crm/pipedrive/summary?org_id=${org_id}&deal_id=${deal_id}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchXeroAuthentication = async (code: string) => {
	const redirectUrl = process.env.REACT_APP_ENV === "development" && 
                            (window.location.hostname === "127.0.0.1" || 
                            window.location.hostname === "localhost" || 
                            window.location.hostname === "0.0.0.0")
                                ? "http://localhost:3000/customer-success"
                                : process.env.REACT_APP_ENV === "development"
                                ? "https://praeter-dash-dev.web.app/customer-success"
                                : "https://dash.praeter.nl/customer-success";

	const payload = {code: code, grant_type: 'authorization_code', redirect_uri: redirectUrl};
	const requestOptions = await createRequestOptions("POST", payload);

	const response = await fetch(
		`${apiBaseUrl}/xero/authenticate`,
		requestOptions
	)
	const responseData = await response.json();
	return responseData;
};

const fetchXeroEstimates = async (contactId: string, xeroToken: string) => {
	const payload = {contact_id: contactId, XeroToken: xeroToken};
	const requestOptions = await createRequestOptions("POST", payload);

	const response = await fetch(
		`${apiBaseUrl}/offerator/estimates/get`,
		requestOptions
	)
	const responseData = await response.json();
	return responseData;
};

const fetchDataBigquery = async (
	table: string,
	column: string,
	value: string,
	extract: boolean = false,
	limit: number = 10
) => {
	const requestOptions = await createRequestOptions("GET");
	const response = await fetch(
		`${apiBaseUrl}/data/search?table=${table}&column=${column}&value=${value}&extract=${extract}&limit=${limit}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const saveToSharepoint = async (
	clientId: string,
	computationType: string,
	computationId: string,
	filePath: string,
	filename: string
) => {
	const requestOptions = await createRequestOptions("POST");
	const response = await fetch(
		`${apiBaseUrl}/operations/sharepoint/upload?computation_type=${computationType}&client_id=${clientId}&computation_id=${computationId}&file_path=${filePath}&file_name=${filename}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchEBSReport = async (clientId: string, data: any) => {
	data.client_id = clientId;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/screening/report/create?client_id=${clientId}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchQuickrJson = async (updateId: string, calculationId: string, existingExcel: boolean) => {
	const requestOptions = await createRequestOptions("POST", {
		computation_id: calculationId,
		client_id: updateId,
		existingUploaded: existingExcel
	});
	const response = await fetch(
		`${apiBaseUrl}/crm/quickr/production`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const postEstimateCreation = async (input: any) => {
	const requestOptions = await createRequestOptions("POST", input);

	const response = await fetch(
		`${apiBaseUrl}/offerator/estimate/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const postCreateContact = async (input: any) => {
	const requestOptions = await createRequestOptions("POST", input);

	const response = await fetch(
		`${apiBaseUrl}/offerator/contact/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const postGetContact = async (input: any) => {
	const requestOptions = await createRequestOptions("POST", input);

	const response = await fetch(
		`${apiBaseUrl}/offerator/contact/get`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchProductionJson = async (updateId: string, productionY1: number, existingExcel: boolean) => {
	const requestOptions = await createRequestOptions("POST", {
		generation_y1: productionY1,
		client_id: updateId,
		existingUploaded: existingExcel
	});
	const response = await fetch(
		`${apiBaseUrl}/crm/quickr/production`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchPVSimultaneous = async (data: any) => {
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(`${apiBaseUrl}/pv/simultaneous`, requestOptions);
	const responseData = await response.json();
	return responseData;
};

const fetchPVCosts = async (data: any) => {
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(`${apiBaseUrl}/pv/costs`, requestOptions);
	const responseData = await response.json();
	return responseData;
};

const fetchPVComputation = async (
	clientId: string,
	computationId: string,
	data: any
) => {
	data.client_id = clientId;
	data.computation_id = computationId;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/pv/computation/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchMDVComputation = async (measureCallName: string, data: any) => {
	data.energy_saving_measure = measureCallName;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/mdv/computation/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchMDVTemplate = async (
	updateId: string,
	computationId: string,
	measureCallName: string,
	layout: string,
	data: any
) => {
	data.client_id = updateId;
	data.computation_id = computationId;
	data.energy_saving_measure = measureCallName;
	data.layout = layout;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/mdv/template/create/measure?client_id=${updateId}&computation_id=${computationId}&energy_saving_measure=${measureCallName}&layout=${layout}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchPDFTemplate = async (
	clientId: string,
	computationId: string,
	computationType: string
) => {
	const requestOptions = await createRequestOptions("POST");
	const response = await fetch(
		`${apiBaseUrl}/operations/pdf/create?client_id=${clientId}&computation_id=${computationId}&computation_type=${computationType}`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchPVComparison = async (
	clientId: string,
	computationId: string,
	data: any
) => {
	data.client_id = clientId;
	data.computation_id = computationId;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/pv/report/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchMDVIntro = async (clientId: string, section: string, data: any) => {
	const payload_data = {...data, client_id: clientId, section: section};
	const requestOptions = await createRequestOptions("POST", payload_data);
	const response = await fetch(
		`${apiBaseUrl}/mdv/template/create/intro`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchMDVReport = async (
	clientId: string,
	computationId: string,
	data: any
) => {
	const payload_data = {
		...data,
		client_id: clientId,
		computation_id: computationId,
		section: "report",
	};
	const requestOptions = await createRequestOptions("POST", payload_data);
	const response = await fetch(
		`${apiBaseUrl}/mdv/template/create/report`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchSectorComparison = async (data: any) => {
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/public/sector/data`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const fetchSectorOptions = async () => {
	const requestOptions = await createRequestOptions("GET");
	const response = await fetch(
		`${apiBaseUrl}/public/sector/types`,
		requestOptions
	);
	const data = await response.json();
	return data;
};

const fetchSurfaceOptions = async () => {
	const requestOptions = await createRequestOptions("GET");
	const response = await fetch(
		`${apiBaseUrl}/public/sector/surfaces`,
		requestOptions
	);
	const data = await response.json();

	return data;
};

const fetchVAReport = async (clientId: string, data: any) => {
	data.client_id = clientId;
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/va/report/create`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

const postPortfolioData = async (data: any) => {
	const requestOptions = await createRequestOptions("POST", data);
	const response = await fetch(
		`${apiBaseUrl}/bigquery/data/post`,
		requestOptions
	);
	const responseData = await response.json();
	return responseData;
};

export {
	fetchSectorComparison,
	fetchCRMDetails,
	fetchQuickrJson,
	fetchVAReport,
	fetchProductionJson,
	fetchPVSimultaneous,
	fetchPVCosts,
	fetchSurfaceOptions,
	fetchPVComputation,
	fetchPDFTemplate,
	fetchMDVComputation,
	fetchPVComparison,
	fetchEBSReport,
	saveToSharepoint,
	fetchSectorOptions,
	fetchMDVIntro,
	fetchMDVTemplate,
	fetchMDVReport,
	fetchDataBigquery,
	postEstimateCreation,
	postCreateContact,
	postGetContact,
	fetchXeroAuthentication,
	fetchXeroEstimates,
	postPortfolioData	
	};
