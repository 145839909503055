// src/screens/portfolio-screen/components/ProductDetails.tsx
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { Product_group, ProductGroupFieldConfig } from "../types/ProductInterface";
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions";
import { auth } from "../../../config/firebase";
import { getAllCollections } from "../utils/fetchFunctions";
import {formatDate} from "../config/tarifConfig"
import {postPortfolioData} from "../../../services/apiFunctions"


interface ProductDetailsProps {
    selectedProduct: Product_group | null;
    setSelectedProduct: ( value: Product_group | null ) => void;
    allProductGroup: Product_group [];
    setAllProductGroup: ( value: Product_group [] ) => void;
    productGroupFieldConfigs : ProductGroupFieldConfig[]
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ selectedProduct, setSelectedProduct, allProductGroup, setAllProductGroup, productGroupFieldConfigs }) => {
    const [isEditingProductGroup, setIsEditingProductGroup] = useState<boolean>(false);
    const [editedProductGroup, setEditedProductGroup] = useState<Product_group | null>(null);
    const editableFields = productGroupFieldConfigs.filter((config) => !config.readonly);
    

    if (!selectedProduct) {
        return <p>No product selected</p>;
    }  

    const handleEditProductGroupToggle = () => {
        setIsEditingProductGroup(!isEditingProductGroup);
        setEditedProductGroup(selectedProduct);
    };

    const handleProductGroupInputChange = (field: keyof Product_group, value: any) => {
        if (editedProductGroup) {
            setEditedProductGroup({ ...editedProductGroup, [field]: value });
        }
    };

    const handleSaveProductGroup = async () => {
        if (editedProductGroup) {
            console.log("Saving edited product group:", editedProductGroup);
    
            // Add `modified_on` and `modified_by` fields
            const { id, ...productGroupDataWithoutId } = editedProductGroup;
            const updatedProductGroup = {
                ...productGroupDataWithoutId,
                modified_on: formatDate(new Date()), // Current date and time
                modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
            };

            const apiPayload = {...updatedProductGroup, rowkey: id, collection: "product_group", action: "updated"};
    
            try {
                // Save the updated product group to Firestore
                await mergeDoc("product_group", id, updatedProductGroup);

                // Send updated product group data to the API
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
    
                // Exit edit mode and refresh the product group list
                setIsEditingProductGroup(false);
                await getAllCollections("product_group").then(setAllProductGroup);
    
                console.log("Product group saved successfully:", updatedProductGroup);
            } catch (error) {
                console.error("Failed to save product group:", error);
            }
        } else {
            console.error("No product group selected to save");
        }
    };
    

        const handleCreateProductGroup = async () => {
            // Determine the highest existing product number
            const numericProductNumbers = allProductGroup
                .map(group => group.product_number)
                .filter(number => typeof number === "number" && !isNaN(number));
        
            const highestProductNumber = numericProductNumbers.length > 0 ? Math.max(...numericProductNumbers) : 0;
        
            // Generate a new product number
            const newProductNumber = highestProductNumber + 10;
            const customId = `ER_${newProductNumber}`;
            console.log(`New product number: ${newProductNumber}`);
            
        
            // Create a new product group object
            const newProductGroup: Omit<Product_group, "id"> = {
                product_name: "New Product Group", // Pre-fill name for clarity
                prefix: "ER",
                product_number: newProductNumber,
                abbreviation: "",
                description: "",
                method: "",
                created_on: formatDate(new Date()),
                modified_by: auth.currentUser?.displayName || "Unknown User",
                modified_on: formatDate(new Date()),
                note: "",
                owner: auth.currentUser?.displayName || "Unknown User",
            };

            const apiPayload = {...newProductGroup, rowkey: customId, collection: "product_group", action: "created"};
        
            try {
                // Save the new product group to the database
                await createDoc("product_group", newProductGroup, customId);
                
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                // Add the new product group to the state
                const newProductWithId = { ...newProductGroup, id: customId };
                setAllProductGroup([...allProductGroup, newProductWithId]);
        
                // Set the new product as selected and open it in edit mode
                setSelectedProduct(newProductWithId);
                setEditedProductGroup(newProductWithId);
                setIsEditingProductGroup(true);
        
                console.log("New product group created successfully:", newProductWithId);
            } catch (error) {
                console.error("Failed to create a new product group:", error);
            }
        };

        const handleDeleteProductGroup = async () => {
            if (selectedProduct) {
                const confirmDelete = window.confirm("Are you sure you want to delete this product group?");
                if (confirmDelete) {
                    await removeDoc("product_group", selectedProduct.id);
                    const apiPayload = {...selectedProduct, rowkey: selectedProduct.id, collection: "product_group", action: "deleted"};
                    const apiResponse = await postPortfolioData(apiPayload);
                    console.log("API Response:", apiResponse);
                    setSelectedProduct(null);
                    setIsEditingProductGroup(false);
                    getAllCollections("product_group").then(setAllProductGroup);
                }
            }
        };


       


    return (
        
            <div>
                <>
                            {/* Product Group */}
                            
                            <Button 
                                variant="outlined" 
                                onClick={handleEditProductGroupToggle}
                            >
                                {isEditingProductGroup ? "Cancel Edit" : "Edit"}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveProductGroup}
                                disabled={!isEditingProductGroup}
                            >
                                Save
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleCreateProductGroup}
                            >
                                Create New
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleDeleteProductGroup}
                            >
                                Delete 
                            </Button>

                            {isEditingProductGroup ? (
                                editableFields.map((config) => (
                                    <TextField
                                        key={config.key}
                                        label={config.label}
                                        type={config.type}
                                        value={editedProductGroup ? editedProductGroup[config.key] ?? "" : ""}
                                        onChange={(e) => handleProductGroupInputChange(config.key, e.target.value)}
                                        fullWidth
                                        margin="normal"
                                        disabled={config.readonly} // Disable field if readonly is true
                                    />
                                ))
                            ) : (
                                editableFields.map((config) => (
                                    <p key={config.key}>
                                        <strong>{config.label}:</strong> {selectedProduct ? selectedProduct[config.key] as string | number || "N/A" : "N/A"}
                                    </p>
                                ))
                            )}
                        </>
            </div>
    );
};

export default ProductDetails;