import React from 'react';
import {styles} from '../../components/utils/Styles';
import { Paper } from '@mui/material';

const UnauthorizedPage = () => {
  return (
    <div style={{...styles.container, minHeight: "100vh", alignItems: "center"}}>
      				<Paper
					elevation={3}
					style={{padding: "20px", minWidth: '90%', marginLeft: "20px"}}
				>
					{/* <h1 style={styles.heading}></h1> */}
					<h2>Unauthorized Access</h2>
          <p>You do not have permission to view this page.</p>
					

				</Paper>
      {/* Optionally provide additional guidance: */}
      {/* <p>Please contact an administrator if you believe this is an error.</p> */}
      {/* <p>Click here to return to the <a href="/">home page</a>.</p> */}
    </div>
  );
};


export default UnauthorizedPage;