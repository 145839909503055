import React, {ReactNode} from "react";

interface CenterProps {
	children: ReactNode;
	height?: string | number;
}

const Center: React.FC<CenterProps> = ({children, height = 100}) => {
	let useHeight: string;
	if (typeof height === "string") {
		useHeight = height;
	} else {
		useHeight = height + "vh";
	}

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
				height: useHeight,
			}}
		>
			{children}
		</div>
	);
};

export default Center;
