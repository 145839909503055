import { useState, useEffect } from 'react';
import {fetchCRMDetails} from "../services/apiFunctions";

const useFetchCRMData = (clientId: string) => {
    const [crmDetails, setCRMDetails] = useState<any>(null);

    useEffect(() => {
        const fetchCRMData = async () => {
            // Fetch updated CRM details
            const updatedCRM = await fetchCRMDetails(
                clientId.split("_")[1],
                clientId.split("_")[0]
            );
            if (
                updatedCRM?.data?.requested_deal &&
                updatedCRM?.data.requested_deal === clientId &&
                updatedCRM?.data
            ) {
                setCRMDetails(updatedCRM?.data);
            }
        };

        if (clientId !== "") {
            fetchCRMData();
        }
    }, [clientId]);

    return [crmDetails, setCRMDetails] as const;
};

export default useFetchCRMData;
