import React from "react";
import {Stepper, Step, Button, Typography} from "@mui/material";
import {styles} from "../utils/Styles";

interface StageStepperProps {
	steps: string[];
	activeStep: number;
	handleStepChange: (index: number) => void;
	filteredSteps: string[];
}

const StageStepper: React.FC<StageStepperProps> = ({
	steps,
	activeStep,
	handleStepChange,
	filteredSteps,
}) => {
	return (
		<Stepper
			orientation="vertical"
			activeStep={activeStep}
			style={{
				minWidth: "200px",
				maxHeight: "200px",
				maxWidth: "400px",
				margin: "10px auto",
				padding: "20px",
				alignItems: "center",
				borderRadius: "5px",
				minHeight: "40vh",
				backgroundColor: styles.DealSelector.backgroundColor,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				color: styles.DealSelector.color,
			}}
			alternativeLabel
		>
			{steps.map((label, index) => (
				<Step key={label} style={{minWidth: "200px"}}>
					<Button
						onClick={() => handleStepChange(index)}
						disabled={!filteredSteps.includes(label)}
						style={{
							width: "100%",
							backgroundColor: filteredSteps.includes(label)
								? index === activeStep
									? "#4caf50"
									: "#267FB8"
								: "#ccc",
							color: filteredSteps.includes(label) ? "#fff" : "#000",
							borderRadius: "0px",
							fontFamily: "Basic",
							textTransform: "none",
							justifyContent: "flex-start",
							textAlign: "left",
						}}
					>
						<Typography
							variant="body1"
							style={{
								flexGrow: 1,
								textAlign: "center",
								fontSize: "12px",
								fontFamily: "Basic",
							}}
						>
							{label}
						</Typography>
					</Button>
				</Step>
			))}
		</Stepper>
	);
};

export default StageStepper;
