import React, {ReactNode} from "react";
import Sidebar from "../../components/layout/sidebar";

interface MainProps {
	children: ReactNode;
}

const Main: React.FC<MainProps> = ({children}) => {
	return (
		<div style={{display: "flex", height: "100%"}}>
			<Sidebar />
			<div>{children}</div>
		</div>
	);
};

export default Main;
