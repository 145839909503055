import React, {useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ListAltIcon from "@mui/icons-material/ListAlt";
import {addDefaultComparisonPV} from "./defaultInteractions";
import "./styles.css";

interface Props {
	computationType: string;
	options: string[];
	updateId: string;
	selectedInteraction: string;
	interactionData: any[];
	disabled: boolean;
}

function PresetConfigIcon({
	computationType,
	options,
	updateId,
	selectedInteraction,
	disabled,
}: Props) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (option: string) => {
		addDefaultComparisonPV(
			computationType,
			option,
			updateId,
			selectedInteraction
		);
		setAnchorEl(null);
	};

	return (
		<div>
			<Button
				aria-controls="preset-menu"
				aria-haspopup="true"
				onClick={handleClick}
				disabled={disabled}
				className="white-icon"
			>
				<ListAltIcon style={{color: disabled ? "grey" : "white"}} />{" "}
			</Button>
			<Menu
				id="preset-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)} // Corrected onClose function
			>
				{options.map((option, index) => (
					<MenuItem key={index} onClick={() => handleClose(option)}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
}

export default PresetConfigIcon;
