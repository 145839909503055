export const options: Record<string, string>  = {
    perc_ev_percentage: "f0lRjFLusN14fwBmoSS5",
    perc_ev_100: "f6IJJL5buK4JyrnV946Y", 
    production_type_quickr: "RVMolYWbu05kBVYBE68y",
    production_type_year_1: "dRU5qqxz6qA2WjhMmrFH", 
    type_usage_total: "dUGGTplWlrZNpQ56B4OS",
    type_usage_monthly: "3z5rgW9WwgqVwwpmTHFx",
    type_usage_monthly_peak: "8kkjhZqYAGJhNLNOVWZR",
    simultaneous_days_1: "qXHfkOWTzwl7G88SR9FU",
    simultaneous_days_2: "6qK8jRBiCIVOpYiH2Nyu",
    simultaneous_days_3: "eJ2WVgiEVkepYauU3GRq",
    simultaneous_days_4: "zqyMSfCHniFB5CbWsPuk",
    simultaneous_days_5: "XaImiSujgBHVqdIEwemz",
    simultaneous_days_6: "avxEpXlevjTc40t6RR9U",
    simultaneous_days_7: "8ZvVYirwMMRMWPmY5ZE6",

}