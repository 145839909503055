import React from "react";
import {Typography, Paper, Container, Box} from "@mui/material";
import Main from "../../layout/main/Main";
import {useTranslation} from "react-i18next";

const InfoPage: React.FC = () => {
	const {t} = useTranslation();

	return (
		<Main>
			<Container maxWidth="md" style={{marginTop: "20px"}}>
				<Paper elevation={3} style={{padding: "20px", minHeight: "70vh"}}>
					<Typography variant="h4" gutterBottom>
						Welcome to Our Website!
					</Typography>
					<Typography variant="body1" paragraph>
						This is an information page where you can learn more about our
						website and its features.
					</Typography>

					<Box mt={4}>
						<Typography variant="h5" gutterBottom>
							About Us
						</Typography>
						<Typography variant="body1" paragraph>
							We are a dedicated team working to provide valuable services and
							information to our users.
						</Typography>
					</Box>

					<Box mt={4}>
						<Typography variant="h5" gutterBottom>
							Our Services
						</Typography>
						<Typography variant="body1" paragraph>
							Explore the different sections of our website, including solar
							panels, energy-saving tips, rental information, and more.
						</Typography>
					</Box>

					<Box mt={4}>
						<Typography variant="h5" gutterBottom>
							Contact Us
						</Typography>
						<Typography variant="body1" paragraph>
							If you have any questions or feedback, feel free to reach out to
							us at <a href={`mailto:${t("emailLink")}`}>{t("emailLink")}</a>.
						</Typography>
					</Box>
				</Paper>
			</Container>
		</Main>
	);
};

export default InfoPage;
