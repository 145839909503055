import React from "react";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import {styles} from "./Styles";
import DynamicTextFields from "../dynamic-text-bullets";

const debounce = (func: Function, delay: number) => {
	let timerId: NodeJS.Timeout;
	return (...args: any[]) => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

const delayedSetChange = debounce(
	(setChangeFn: React.Dispatch<React.SetStateAction<boolean>>) => {
		setChangeFn(true);
	},
	500
); // Adjust the delay as needed

interface Option {
	id: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: any;
}

interface FieldInfo {
	name: string;
	name_en: string;
	mandatory: boolean;
	name_clean: string;
	options?: Option[];
	description: string;
	data_type: string;
	conditional_field?: string;
	conditional_values?: string[];
	input_categories?: string[];
	default_value?: string;
	rank?: number;
}

interface FormData {
	[key: string]: any;
}

interface RenderInputFieldsProps {
	template: Record<string, FieldInfo>;
	formData: FormData;
	setFormData: React.Dispatch<React.SetStateAction<FormData>>;
	setChange: React.Dispatch<React.SetStateAction<boolean>>;
	t: any;
	filter?: string;
}

const handleInputChange = (
	fieldId: string,
	value: any,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setFormData((prevData) => ({
		...prevData,
		[fieldId]: value,
	}));
	delayedSetChange(setChange);
};

const renderPercentageField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<div style={{display: "flex", alignItems: "center"}}>
		<Slider
			value={
				formData[fieldId] !== undefined && formData[fieldId] !== null
					? formData[fieldId]
					: Number(fieldInfo?.default_value) || null
			}
			onChange={(e, value) =>
				handleInputChange(fieldId, Number(value), setFormData, setChange)
			}
			min={0}
			max={100}
			step={1}
			style={{width: "200px", marginRight: "10px"}}
		/>
		<span>
			{formData[fieldId] !== undefined
				? `${formData[fieldId]}%`
				: Number(fieldInfo.default_value)
				? `${Number(fieldInfo.default_value)}%`
				: "0%"}
		</span>
	</div>
);

const renderIntegerField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<input
		type="number"
		value={
			formData[fieldId] !== undefined && formData[fieldId] !== null
				? formData[fieldId]
				: Number(fieldInfo?.default_value) || null
		}
		onChange={(e) =>
			handleInputChange(fieldId, Number(e.target.value), setFormData, setChange)
		}
		style={styles.input}
	/>
);
const renderStringField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<input
		type="text"
		value={
			formData[fieldId] !== undefined && formData[fieldId] !== null
				? formData[fieldId]
				: fieldInfo?.default_value || ""
		}
		onChange={(e) =>
			handleInputChange(fieldId, e.target.value, setFormData, setChange)
		}
		style={styles.input}
	/>
);

const renderDecimalField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<input
		type="number"
		step="any"
		value={
			formData[fieldId] !== undefined && formData[fieldId] !== null
				? formData[fieldId]
				: Number(fieldInfo?.default_value) || null
		}
		onChange={(e) =>
			handleInputChange(fieldId, Number(e.target.value), setFormData, setChange)
		}
		style={styles.input}
	/>
);

const renderDateField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<input
		type="date"
		value={formData[fieldId] || fieldInfo?.default_value || ""}
		onChange={(e) =>
			handleInputChange(fieldId, e.target.value, setFormData, setChange)
		}
		style={styles.input}
	/>
);

const renderBooleanField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<Switch
		checked={
			formData[fieldId] || fieldInfo?.default_value === "true" ? true : false
		}
		onChange={(e) =>
			handleInputChange(fieldId, !formData[fieldId], setFormData, setChange)
		}
		color="primary"
	/>
);

const renderTextField = (
	fieldId: string,
	fieldInfo: FieldInfo,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<textarea
		placeholder={fieldInfo?.description || "Enter your text here"}
		value={formData[fieldId] || fieldInfo?.default_value || ""}
		onChange={(e) =>
			handleInputChange(fieldId, e.target.value, setFormData, setChange)
		}
		style={{
			margin: "0 0 10px",
			padding: "8px",
			borderRadius: "4px",
			fontSize: "14px",
			marginBottom: "10px",
			fontFamily: "Karla",
			minHeight: "60px",
			minWidth: "95%",
			resize: "vertical",
			border: "none",
			outline: "none",
			boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
		}}
	/>
);

const renderDropdown = (
	fieldId: string,
	selectedValue: string,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>,
	dropdownOptionsData: Option[]
) => (
	<select
		value={selectedValue || ""}
		onChange={(e) =>
			handleInputChange(fieldId, e.target.value, setFormData, setChange)
		}
		style={styles.input}
	>
		<option value="" disabled>
			Select an option
		</option>
		{dropdownOptionsData.filter(item => item?.name).map((option) => (
			<option key={option.name} value={option.id}>
				{option.name}
			</option>
		))}
	</select>
);

const renderTextBulletsField = (
	fieldId: string,
	formData: FormData,
	setFormData: React.Dispatch<React.SetStateAction<FormData>>,
	setChange: React.Dispatch<React.SetStateAction<boolean>>
) => (
	<DynamicTextFields
		fieldId={fieldId}
		textFields={formData}
		setTextFields={setFormData}
	/>
);

const renderInputFields: React.FC<RenderInputFieldsProps> = ({
	template,
	formData,
	setFormData,
	setChange,
	t,
	filter = "",
}: RenderInputFieldsProps) => {

	let filteredRecords: Record<string, FieldInfo> = {};
	if (filter !== "") {
		Object.keys(template).forEach((key) => {
			const record = template[key];
			if (record.input_categories && record.input_categories.map((item:any) => item.name_clean).includes(filter)) {
				filteredRecords[key] = record;
			}
		});
	} else {
		filteredRecords = template;
	}
	const fieldEntries = Object.entries(filteredRecords);
	const sortedFields = fieldEntries.sort(([, fieldInfoA], [, fieldInfoB]) => {
		const rankA = fieldInfoA?.rank || 0;
		const rankB = fieldInfoB?.rank || 0;
		return rankA - rankB;
	});
	Object.keys(filteredRecords).forEach((fieldId) => {
		if (
			(!formData?.[fieldId] ||
				formData[fieldId] === "" ||
				formData[fieldId] === null ||
				formData[fieldId] === undefined) &&
			template[fieldId]?.default_value
		) {
			if (
				["PERCENTAGE", "INTEGER", "DECIMAL"].includes(
					template[fieldId].data_type
				)
			) {
				setFormData((prevData) => ({
					...prevData,
					[fieldId]: Number(template[fieldId].default_value),
				}));
			} else {
				setFormData((prevData) => ({
					...prevData,
					[fieldId]: template[fieldId].default_value,
				}));
				setChange(true);
			}
		}
	});

	return sortedFields.map(([fieldId, fieldInfo]) => (
		<div key={fieldId} style={styles.inputWrapper}>
			{!(
				fieldInfo?.conditional_field &&
				fieldInfo?.conditional_field !== "" &&
				fieldInfo?.conditional_values &&
				!fieldInfo?.conditional_values.includes(
					formData[fieldInfo?.conditional_field]
				)
			) && (
				<div>
					<label
						style={{fontSize: "14px", fontFamily: "Karla", fontWeight: "bold"}}
					>
						{fieldInfo.mandatory && <span style={{color: "red"}}>*</span>}
						{t("language") === "Language" && fieldInfo?.name_en
							? fieldInfo?.name_en
							: fieldInfo?.name}
					</label>
				</div>
			)}
			{!(
				fieldInfo?.conditional_field &&
				fieldInfo?.conditional_field !== "" &&
				fieldInfo?.conditional_values &&
				!fieldInfo?.conditional_values.includes(
					formData[fieldInfo?.conditional_field]
				)
			) &&
			fieldInfo?.options &&
			fieldInfo.options.length > 0 && (formData[fieldId] || fieldInfo?.default_value)
				? renderDropdown(
						fieldId,
						formData[fieldId] || fieldInfo?.default_value,
						setFormData,
						setChange,
						fieldInfo.options
				  )
				: !(
						fieldInfo?.conditional_field &&
						fieldInfo?.conditional_field !== "" &&
						fieldInfo?.conditional_values &&
						!fieldInfo?.conditional_values.includes(
							formData[fieldInfo?.conditional_field]
						)
				  ) && (
						<>
							{fieldInfo.data_type === "PERCENTAGE" &&
								renderPercentageField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "INTEGER" &&
								renderIntegerField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "DECIMAL" &&
								renderDecimalField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "DATE" &&
								renderDateField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "BOOLEAN" &&
								renderBooleanField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "TEXT" &&
								renderTextField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "TEXT_BULLETS" &&
								renderTextBulletsField(
									fieldId,
									formData,
									setFormData,
									setChange
								)}
							{fieldInfo.data_type === "STRING" &&
								renderStringField(
									fieldId,
									fieldInfo,
									formData,
									setFormData,
									setChange
								)}
						</>
				  )}
		</div>
	));
};

export default renderInputFields;