import React, {useEffect, useState, useRef} from "react";
import {
	Box,
  CircularProgress,
} from "@mui/material";
import MultiDropdownTable from "../../components/data-multi-dropdown/DataMultiDropdown";
import { styles } from "../../components/utils/Styles";
import './index.css'
import { useTranslation } from "react-i18next";
import { postEstimateCreation, fetchDataBigquery, postGetContact } from "../../services/apiFunctions";

interface Product {
	id: string;
	phase: string;
	name: string;
	description: string;
	quantity?: number;
	prices: {currency: string; price: number}[];
}

interface Option {
	id: string;
	name: string;
	name_en: string;
	value?: string;
  values?: string[];
  description?: string;
}

interface DealData {
  id: string
  deal_id: string;
  deal_rowkey: string;
  deal_title: string;
  org_id: string;
  org_rowkey: string;
}

interface EstimateInput {
    selectedProducts: any
    selectedClientData: any
    selectedDealData: {selectedWorkflow?: string[]
                       selectedEstimateText: string
                       UK: boolean
                       dealTitle: string
                       dealId: string,
                       dealRowkey: string,
                       contact_id: string,
                       buildingArea: string,
                       isEnergieLabel: boolean}
}

interface CreateEstimateSectionProps {
	selectedProducts: Product[];
    selectedClientData: any;
    workflows: Option[];
    estimateTexts: Option[];
    deal: DealData;
    estimateCreated: boolean;
    setEstimateCreated: (value: boolean) => void;
    clientId: string;
    isUK: boolean;
    XeroToken: string | null;
    newContact: string;
    setNewContact: (value: string) => void;
}

const CreateEstimateSection: React.FC<CreateEstimateSectionProps> = ({
	selectedProducts,
  selectedClientData,
	workflows,
	estimateTexts,
  deal,
  estimateCreated,
  setEstimateCreated,
  clientId,
  isUK,
  XeroToken,
  newContact,
  setNewContact
}) => {
	  const [selectedWorkflow, setSelectedWorkflow] = useState<Option | null>(null)
    const [selectedEstimateText, setSelectedEstimateText] = useState<Option | null>(null)
    const { t } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState<string>('nl');
    //const [newContact, setNewContact] = useState<string>("");
    const [searchingContact, setSearchingContact] = useState<boolean>(false);
    const [isValidContact, setIsValidContact] = useState<boolean>(false);
    const [pipedriveError, setPipedriveError] = useState<boolean>(false);
    const [estimateError, setEstimateError] = useState<boolean>(false);
    const [isEnergieLabel, setIsEnergieLabel] = useState<boolean>(false);
    const [buildingArea, setBuildingArea] = useState<string>("0");
    const [estimateData, setEstimateData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const textareaRefStandardText = useRef(null);
    const textareaRefWorkflow = useRef(null);

    useEffect(() => {
        if (textareaRefStandardText.current) {
            (textareaRefStandardText.current as HTMLTextAreaElement).style.height = 'auto';
            (textareaRefStandardText.current as HTMLTextAreaElement).style.height = `${(textareaRefStandardText.current as HTMLTextAreaElement).scrollHeight}px`;
        }
        if (textareaRefWorkflow.current) {
          (textareaRefWorkflow.current as HTMLTextAreaElement).style.height = 'auto';
          (textareaRefWorkflow.current as HTMLTextAreaElement).style.height = `${(textareaRefWorkflow.current as HTMLTextAreaElement).scrollHeight}px`;
      }
    }, [selectedEstimateText, selectedWorkflow]);

    const handleButtonClick = () => {
        if (
          selectedProducts.length > 0 &&
          selectedClientData.length > 0 &&
          selectedEstimateText?.value &&
          selectedWorkflow?.name
        ) {
          const dataObject = {
            selectedProducts: selectedProducts, 
            selectedClientData: selectedClientData,
            selectedDealData: {selectedWorkflow: selectedWorkflow?.values,
                               selectedEstimateText: selectedEstimateText?.value,
                               UK: isUK,
                               XeroToken: XeroToken,
                               dealTitle: deal.deal_title,
                               dealId: deal.deal_id,
                               dealRowkey: deal.deal_rowkey,
                               contact_id: newContact,
                               buildingArea: buildingArea,
                               isEnergieLabel: isEnergieLabel,
                               clientIdCheck: clientId}
          };
          
          setIsLoading(true);
          handleCreateEstimate(dataObject); // Pass dataObject to a handler function
        } else {
          console.error('Please ensure all fields are selected');
        }
      };

      const handleEstimateButtonClick = () => {
        if (isUK){
          //const url = estimateData.xero_url;
          const url = `https://go.xero.com/app/!tg-HS/quotes/edit/${estimateData.xero_id}`
          window.open(url, '_blank');
        }
        else{
          const url = estimateData.moneybird_url;
          window.open(url, '_blank');
        }
      };

      const handleCreateEstimate = (input: EstimateInput) => {
        postEstimateCreation(input).then((data: any) => {
          if (data) {
            if (data.success){
              setEstimateData(data.data)
            }
            setEstimateCreated(data.success)
            setEstimateError(!data.success)
            setIsLoading(false);
            }
        }).catch(() => {
          setIsLoading(false);
        });
      };

      const handleGetContacts = () => {
        if (selectedClientData && selectedClientData.length === 1) {
          if (isUK && !XeroToken){
            console.error('Please ensure that the user is logged in to Xero');
            setSearchingContact(false);
          }
          else{
            console.log("Getting contact")
            setSearchingContact(true);
            const rowkey = selectedClientData[0]?.rowkey
            fetchDataBigquery(
              "app.pipedrive_org_person",
              "organization_rowkey",
              rowkey.toString()
            )
              .then((data: any) => {
                if (data && data.data[0]) {
                  console.log(data.data[0])
                  const dataObject = {
                    data: data.data[0],
                    UK: isUK,
                    XeroToken: XeroToken
                  };
                  postGetContact(dataObject).then((data: any) => {
                    if (data && data.success) {
                      console.log("result", data)
                      if (isUK){
                        setNewContact(data.data.xero_id)
                      }
                      else{
                        setNewContact(data.data.moneybird_id)
                      }
                      setIsValidContact(true)
                      setSearchingContact(false);
                    }
                    setSearchingContact(false);
                  });
                }
              })
              .catch((error) => {
                console.error("Error fetching data:", error);
                setSearchingContact(false);
              });
          } 
        } 
        else {
            console.error('Please ensure a valid client is selected');
            setSearchingContact(false);
        }
     
      };

    useEffect(() => {
		if (t('language') === 'Taal'){
			setSelectedLanguage('nl')
		}
		else{
			setSelectedLanguage('en')
		}
	  }, [t]);

    useEffect(() => {
        if (selectedEstimateText && selectedEstimateText.name === 'Energielabel' && selectedEstimateText.value){
            setIsEnergieLabel(true);
        }
        else{
            setIsEnergieLabel(false);
            setBuildingArea("0"); 
        }
    }, [selectedEstimateText]);

    useEffect(() => {
      setEstimateCreated(false)
    },[selectedWorkflow, selectedEstimateText, isUK, selectedProducts, selectedClientData])

	return (
		<Box
			sx={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				alignItems: "top",
				borderRadius: "5px",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
				padding: "10px",
			}}
		>
			<h2 style={{marginLeft: "10px"}}>{t("estimate_title")}</h2>
            <div style={{
              flex: 1,
              display: "flex",
              flexDirection: "row",
              maxHeight: "60vh",
              minWidth: "30vw",
              gap: "25px",
              padding: "10px"
            }}>
            <div style={{minWidth: "30vw"}}>
            {t("workflow")}
			  	  <MultiDropdownTable
                options = {workflows as any}
                selectedOption={selectedWorkflow}
                setSelectedOption={setSelectedWorkflow}
                type='estimate'
            />
            <textarea
                ref={textareaRefWorkflow}
                value={selectedWorkflow?.description !== null ? selectedWorkflow?.description : ''}
                style={{ ...styles.input, 
                    marginRight: '8px',
                    width: '100%',  
                    fontSize: '14px', 
                    boxSizing: 'border-box',
                    overflow: 'hidden', 
                    resize: 'none'  
                }}
                readOnly
            />
            
            {isEnergieLabel && (
            <div className="el_padding">
            {<span className="deal_text"> {t("building_m2")} </span>}
            <input 
                type="number"  
                value={buildingArea}
                onChange={(e) => setBuildingArea(e.target.value)} 
                min={"0"}
            />
            </div>
            )}
            </div>
            <div style={{minWidth: "30vw"}}> {t("standard_text")}
            <MultiDropdownTable
                options = {estimateTexts as any}
                selectedOption={selectedEstimateText}
                setSelectedOption={setSelectedEstimateText}
                type='estimate'
            />
            <textarea
                ref={textareaRefStandardText}
                value={selectedEstimateText?.value !== null ? selectedEstimateText?.value : ''}
                style={{ ...styles.input, 
                    marginRight: '8px',
                    width: '100%',  
                    fontSize: '14px', 
                    boxSizing: 'border-box',
                    overflow: 'hidden', 
                    resize: 'none'     
                }}
                readOnly
            />
            </div>
            </div>
            <div className="split_div">
            
            <div className="right_div">
              {/* Case 1: Invalid contact and not searching */}
              {!isValidContact && !searchingContact && (
                <button className="right_button" onClick={handleGetContacts}>
                  {t("search_contact")}
                </button>
              )}

              {/* Case 2: Invalid contact and currently searching */}
              {!isValidContact && searchingContact && <CircularProgress />}

              {/* Case 3: All conditions for creating estimate are met */}
              {selectedProducts.length > 0 &&
                selectedClientData.length > 0 &&
                selectedEstimateText?.name &&
                selectedWorkflow?.name &&
                isValidContact &&
                !estimateCreated &&
                !isLoading &&
                !searchingContact && (
                  <div className="split_div">
                    <span className="estimate_text"> {t("contact_found")} </span>
                    <button className="right_button" onClick={handleButtonClick}>
                      {t("create_estimate")}
                    </button>
                  </div>
              )}
            </div>
            {isLoading && (
              <div className="right_button">
                {<CircularProgress />}
              </div>
            )}
            {estimateCreated && (
            <div className="right_div">
            <button className="right_button" onClick={handleEstimateButtonClick}>
              {t("nav_estimate")}
            </button>
            {<span className="estimate_text"> {t("made_estimate")} </span>}
            </div>
            )}
            {estimateError && (
              <div className="right_div">
            <span className="estimate_text"> {t("error_estimate")} </span>
            </div>
            )}
            </div>
          
		</Box>
	);
};

export default CreateEstimateSection;
