import React, {useEffect} from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import TextSpec from "../../components/input-text";

interface ReportPrepProps {
	updateId: string;
	pageReady: boolean;
	generalData: any; // Add clientId prop here
	template: any;
	// Add clientId prop here
}

const EnergyScreeningReportNotes: React.FC<ReportPrepProps> = ({
	updateId,
	pageReady,
	template,
}) => {
	useEffect(() => {
		let status = true;

		if (!(status === pageReady)) {
			mergeDoc("energy_screening_computations", updateId, {
				progress: {
					3: {
						done: status,
						name: "Free text",
					},
				},
				approved: false,
			});
		}
	}, [updateId]);

	return (
		<div
			style={{
				minHeight: "100vh",
				minWidth: "70vw",
				maxWidth: "70vw",
				overflowY: "auto",
			}}
		>
			<div style={{...styles.rightPanelStyle, maxHeight: "90vh"}}>
				<h3 style={{fontSize: "1rem"}}>Adviesteksten</h3>
				<TextSpec
					updateId={updateId}
					templateUnfiltered={template}
					filter={"advice_text"}
					computationType={"energy_screening_computations"}
				/>
			</div>
		</div>
	);
};
export default EnergyScreeningReportNotes;
