import React, {useEffect, useState} from "react";
import {styles} from "../utils/Styles";
import PresetConfigIcon from "../utils/presetConfigIcon";
import {addInteraction, deleteInteraction} from "../utils/defaultInteractions";
import "./styles.css";
import {mergeDoc} from "../../services/firebaseFunctions";
import {useTranslation} from "react-i18next";

interface Difference {
	name: string;
	name_en: string;
	values: {name: string; value: string}[];
}

interface SelectableProgressBarProps {
	updateId: string;
	interactionData: any[];
	selectedInteraction: string;
	setSelectedInteraction: React.Dispatch<React.SetStateAction<string>>;
	differences: Difference[];
	computationType: string;
}

const SelectableProgressBar: React.FC<SelectableProgressBarProps> = ({
	updateId,
	interactionData,
	selectedInteraction,
	setSelectedInteraction,
	differences,
	computationType,
}) => {
	const {t} = useTranslation();
	const [sortedInteractionData, setSortedInteractionData] = useState<any[]>([]);

	let presetOptions: any = [];
	if (computationType === "solar_energy_computations") {
		presetOptions = ["Standaard Buca EV vergelijking"];
	} else if (computationType === "solar_rental_computations") {
		presetOptions = ["Standaard verreken advies"];
	}

	useEffect(() => {
		if (interactionData) {
			const sortedData = [...interactionData].sort((a, b) => a.rank - b.rank);
			setSortedInteractionData(sortedData);
		}
	}, [interactionData]);

	useEffect(() => {
		if (
			interactionData
				.map((interaction) => interaction.id)
				.includes(selectedInteraction) === false
		) {
			if (sortedInteractionData[0]?.id) {
				setSelectedInteraction(sortedInteractionData[0].id);
			}
		}
	}, [
		interactionData,
		selectedInteraction,
		sortedInteractionData,
		setSelectedInteraction,
	]);
	

	const handleDeleteInteraction = (selectedclick: string) => {
		deleteInteraction(computationType, updateId, selectedclick);
		setSelectedInteraction(sortedInteractionData[0].id);
	};

	const handleNameChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		id: string
	) => {
		mergeDoc(`${computationType}/${updateId}/computation_inputs`, id, {
			name: e.target.value,
		});
	};

	const handleAddInteraction = async () => {
		addInteraction(
			computationType,
			updateId,
			selectedInteraction,
			interactionData
		);
	};
	return (
		<div
			style={{
				maxHeight: "90vh",
				overflowY: "auto",
				minWidth: "200px",
				maxWidth: "200px",
				...styles.rightPanelStyle,
				backgroundColor: styles.DealSelector.backgroundColor,
				color: styles.DealSelector.color,
				marginLeft: "-10px",
				marginTop: "-00px",
				overflowX: "hidden", // Prevent horizontal scrollbar
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "right",
					justifyContent: "flex-end",
					marginRight: "-25px",
					overflowX: "hidden", // Prevent horizontal scrollbar
					marginBottom: "-10px",
				}}
			>
				<PresetConfigIcon
					computationType={computationType}
					options={presetOptions}
					updateId={updateId}
					selectedInteraction={selectedInteraction}
					interactionData={interactionData}
					disabled={interactionData.length === 1 ? false : true}
				/>
			</div>
			{sortedInteractionData.map((measure) => (
				<div
					key={measure.id}
					onClick={() => setSelectedInteraction(measure.id)}
					style={{
						marginTop: "20px",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between", // Align items and delete cross to opposite ends
						cursor: "pointer",
						backgroundColor:
							selectedInteraction === measure.id ? "#4CAF50" : "#267FB8",
						fontSize: "12px",
						padding: "4px",
						minHeight: "20px",
					}}
				>
					{measure.name &&
					measure.rank > 0 &&
					computationType !== "energy_saving_computations" ? (
						<input
							type="text"
							value={
								t("language") === "Language" && measure?.name_en
									? measure.name_en
									: measure.name
							}
							onChange={(e) => handleNameChange(e, measure.id)}
							style={{
								...styles.input,
								backgroundColor: "transparent",
								fontSize: "12px",
								color: "white",
								padding: "0px",
								minHeight: "20px",
							}}
						/>
					) : (
						<p style={{margin: 0, wordWrap: "break-word", color: "white"}}>
							{t("language") === "Language" && measure?.name_en
								? measure.name_en
								: measure.name}
						</p>
					)}
					{measure.rank > 0 &&
						computationType !== "energy_saving_computations" && ( // Render delete button conditionally
							<button
								onClick={(e) => {
									e.stopPropagation();
									handleDeleteInteraction(measure.id);
								}}
								style={{
									backgroundColor: "transparent",
									border: "none",
									color: "white",
									cursor: "pointer",
								}}
							>
								&#10006;
							</button>
						)}
				</div>
			))}
			{(computationType === "solar_energy_computations" ||
				computationType === "solar_rental_computations") && (
				<div
					style={{
						display: "flex",
						alignItems: "right",
						justifyContent: "flex-end",
					}}
				>
					<button
						onClick={handleAddInteraction}
						style={{
							backgroundColor: "transparent",
							border: "none",
							borderRadius: "5px",
							cursor: "pointer",
							fontSize: "24px",
							color: "white",
							marginLeft: "0px",
							marginRight: "-10px",
						}}
					>
						+
					</button>
				</div>
			)}
			{differences && differences.length > 0 && (
				<div style={{marginTop: "20px"}}>
					<h3>{t("differences")}</h3>
					<table>
						<tbody>
							{differences.map((difference, index) => (
								<tr key={index}>
									<td>
										<div className="values-list">
											{t("language") === "Language" && difference?.name_en
												? difference.name_en
												: difference.name}
											{difference.values
												.sort((a, b) =>
													a.name && b.name ? a.name.localeCompare(b.name) : 0
												)
												.map((record: any, recordIndex) => (
													<div key={recordIndex}>
														<span className="value-name">
															{String(
																t("language") === "Language" && record?.name_en
																	? record.name_en
																	: record.name
															).slice(0, 12)}
															:
														</span>
														<span className="value">
															{String(record.value).slice(0, 12)}
														</span>
													</div>
												))}
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}{" "}
		</div>
	);
};
export default SelectableProgressBar;
