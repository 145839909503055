import React, {useState, useEffect} from "react";
import {
	Typography,
	ListItem,
	Collapse,
	List,
	ListItemText,
	Checkbox,
} from "@mui/material";
import {styles} from "../../components/utils/Styles";
import {
	fetchDocsSnap,
	mergeDoc,
	fetchDocData,
	queryCollection,
	removeDoc,
} from "../../services/firebaseFunctions";
import MeasureManagement from "../../components/drag-table/DragTable";
import TextSpec from "../../components/input-text";

interface Measure {
	id: string;
	name: string;
	selected: boolean;
	energy_saving_category_id: string;
}

interface MeasuresSectionProps {
	template: any;
	updateId: string;
	listRooms: {[roomId: string]: any};
	measures: Measure[];
	interactionData: any[];
	pageReady: boolean;
}

const MeasuresSection: React.FC<MeasuresSectionProps> = ({
	template,
	updateId,
	listRooms,
	measures,
	interactionData,
	pageReady,
}) => {
	const [groupedMeasures, setGroupedMeasures] = useState<{
		[categoryId: string]: Measure[];
	}>({});
	const [expanded, setExpanded] = useState<{[categoryId: string]: boolean}>({});
	const [energySavingMeasures, setEnergySavingMeasures] = useState<Measure[]>(
		[]
	);
	const [categoryNames, setCategoryNames] = useState<{
		[categoryId: string]: string;
	}>({});
	const [checkedDict, setCheckDict] = useState<{[key: string]: boolean}>({});

	const handleCopyToUpdated = async (measureId: string) => {
		try {
			const measureData = await fetchDocData(
				"energy_saving_measures",
				measureId
			);
			await mergeDoc(
				`energy_saving_computations/${updateId}/measures`,
				measureId,
				{
					...measureData,
					room_split: false,
				}
			);
		} catch (error) {
			console.error("Error copying measure:", error);
		}
	};

	useEffect(() => {
		const generalInteractionId: string = interactionData.find(
			(item) => item.name === "Algemene berekening"
		)?.id;

		if (generalInteractionId) {
			const measureIds = measures.map((item) => item.id);
			mergeDoc(
				`energy_saving_computations/${updateId}/computation_inputs`,
				`${generalInteractionId}`,
				{measureIds: measureIds}
			);
		}
	}, [measures, updateId]);

	useEffect(() => {
		const status = measures.every((measure) =>
			measure.hasOwnProperty("phase_id")
		);
		if (!(status === pageReady)) {
			mergeDoc("energy_saving_computations", updateId, {
				progress: {
					2: {
						done: status,
						name: "Measures",
					},
				},
			});
		}
	}, [measures]);

	useEffect(() => {
		const fetchEnergySavingMeasures = async () => {
			try {
				const measuresAll: any = await queryCollection(
					"energy_saving_measures",
					{ready: true}
				);
				setEnergySavingMeasures(measuresAll);
				// setLoading(false);
			} catch (error) {
				console.error("Error fetching energy saving measures:", error);
			}
		};
		fetchEnergySavingMeasures();
	}, []);

	const handleMeasureToggle = async (measureId: string) => {
		if (!measures.find((item) => measureId === item.id)) {
			handleCopyToUpdated(measureId);
		} else {
			removeDoc(
				`energy_saving_computations/${updateId}/measures`,
				`${measureId}`
			);
		}
	};

	useEffect(() => {
		const fetchEnergySavingCategories = async () => {
			const CategoryData = await fetchDocsSnap("energy_saving_categories");

			if (CategoryData) {
				const names: {[key: string]: string} = {}; // Specify type for names object
				CategoryData.docs.forEach((doc) => {
					const data = doc.data() as {name: string}; // Assert type of data
					names[doc.id] = data.name;
				});
				setCategoryNames(names);
			} else {
				// Handle the case when CategoryData is null
				// For example, set categoryNames to an empty object
				setCategoryNames({});
			}
		};
		fetchEnergySavingCategories();
	}, []);

	useEffect(() => {
		const buildCheckDict = async () => {
			const newCheckDict = {...checkedDict};
			measures
				.filter((measure) => measure.selected)
				.map((selectedMeasure) => selectedMeasure.id)
				.forEach((measureId) => {
					Object.keys(listRooms).forEach((roomId) => {
						const checkboxKey = `${roomId}_${measureId}`;
						const defaultValue =
							listRooms[roomId]?.energy_saving_measures?.includes(measureId) ||
							false;
						newCheckDict[checkboxKey] = defaultValue;
					});
				});
			setCheckDict(newCheckDict);
		};

		if (energySavingMeasures.length > 0 && Object.keys(listRooms).length > 0) {
			buildCheckDict();
		}
	}, [measures, listRooms, updateId]);

	// const CheckTable: React.FC = () => {
	// 	return (
	// 		<table style={{ borderCollapse: "collapse" }}>
	// 			<thead>
	// 				<tr>
	// 					<th style={{ padding: "8px" }}></th>
	// 					{Object.keys(listRooms).map((roomId) =>
	// 						listRooms[roomId]["name_clean"] !== "all" ? (
	// 							<th
	// 								key={roomId}
	// 								style={{ ...styles.labelLower, minWidth: "100px" }}
	// 							>
	// 								{listRooms[roomId]["name"]}
	// 							</th>
	// 						) : null
	// 					)}
	// 				</tr>
	// 			</thead>
	// 			<tbody>
	// 				{measures
	// 					.filter((measure) => measure.selected)
	// 					.map((measureSel) => (
	// 						<tr key={measureSel.id} style={styles.labelLower}>
	// 							<td style={{ padding: "8px" }}>{measureSel.name}</td>
	// 							{Object.keys(listRooms).map((roomId) =>
	// 								listRooms[roomId]["name_clean"] !== "all" ? (
	// 									<td key={roomId} style={{ padding: "8px" }}>
	// 										<input
	// 											type="checkbox"
	// 											id={`checkbox_${roomId}_${measureSel.id}`}
	// 											checked={checkedDict[`${roomId}_${measureSel.id}`]}
	// 											onChange={() =>
	// 												handleRoomsToggle(measureSel.id, roomId)
	// 											}
	// 										/>
	// 									</td>
	// 								) : null
	// 							)}
	// 						</tr>
	// 					))}
	// 			</tbody>
	// 		</table>
	// 	);
	// };
	const handleExpand = (categoryId: string) => {
		setExpanded((prevExpanded) => ({
			...prevExpanded,
			[categoryId]: !prevExpanded[categoryId],
		}));
	};

	useEffect(() => {
		const updateGroupedMeasures = () => {
			const updatedGroupedMeasures: {[categoryId: string]: Measure[]} =
				energySavingMeasures.reduce((acc: any, measure) => {
					const {id, name, energy_saving_category_id} = measure;
					const category = energy_saving_category_id || "Uncategorized";
					acc[category] = [...(acc[category] || []), {id, name}];
					return acc;
				}, {});
			setGroupedMeasures(updatedGroupedMeasures);
		};

		if (energySavingMeasures.length > 0) {
			updateGroupedMeasures();
		}
	}, [energySavingMeasures, categoryNames, updateId]);

	return (
		<div
			style={{
				minHeight: "70vh",
				maxHeight: "100vh",
				overflowY: "auto",
				display: "flex",
				minWidth: "70vw",
				marginLeft: "10px",
			}}
		>
			<div
				style={{
					flex: 1,
					textAlign: "left",
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					minHeight: "100vh",
				}}
			>
				<div
					style={{
						...styles.rightPanelStyle,
						minWidth: "18vw",
						maxHeight: "90vh",
					}}
				>
					<h3 style={{fontSize: "1rem"}}>Advies mogelijke maatregelen</h3>
					{/* <CheckTable /> */}
					<TextSpec
						updateId={updateId}
						templateUnfiltered={template}
						filter={"advice_possible_measures"}
						computationType={"energy_saving_computations"}
					/>
				</div>
			</div>
			<div style={{flex: 1}}>
				<div style={{display: "flex", flex: "1"}}>
					<div
						style={{
							...styles.rightPanelStyle,
							marginLeft: "10px",
							minHeight: "90vh",
							minWidth: "15vw",
							maxWidth: "15vw",
							fontFamily: "Karla",
						}}
					>
						<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
							Maatregelen voor selectie
						</h3>

						{Object.entries(groupedMeasures).map(
							([categoryId, measuresAll]) => (
								<div key={categoryId}>
									<ListItem
										disablePadding
										onClick={() => handleExpand(categoryId)}
										sx={{...styles.labelLower, fontWeight: "bold"}}
									>
										{/* <ListItemIcon>
											<CategoryIcon />
										</ListItemIcon> */}
										<Typography
											variant="subtitle1"
											gutterBottom
											style={{...styles.labelLower, fontWeight: "bold"}}
										>
											{categoryNames[categoryId]}
											{` (${
												measuresAll.filter((measure) => {
													const selectedMeasure = measuresAll.find(
														(item) => item.id === measure.id
													);
													return (
														selectedMeasure &&
														measures.find((item) => item.id === measure.id)
													);
												}).length
											}/${measuresAll.length})`}
										</Typography>
									</ListItem>
									<Collapse
										in={expanded[categoryId]}
										timeout="auto"
										unmountOnExit
									>
										<List dense>
											{measuresAll.map((measure) => (
												<ListItem
													key={measure.id}
													sx={{...styles.labelLower, fontFamily: "karla"}}
												>
													<ListItemText
														primary={measure.name}
														sx={{fontFamily: "karla"}}
													/>
													<Checkbox
														checked={
															Object.keys(
																measures.find(
																	(item) => item.id === measure.id
																) || {}
															).length > 0
														}
														onChange={() => handleMeasureToggle(measure.id)}
														color="primary"
													/>
												</ListItem>
											))}
										</List>
									</Collapse>
								</div>
							)
						)}
					</div>

					<div
						style={{
							flex: 1,
							textAlign: "left",
							display: "flex",
							flexDirection: "column",
							justifyContent: "flex-start",
							minWidth: "700px",
							maxWidth: "40vw",
							marginLeft: "10px",
						}}
					>
						<div style={{...styles.rightPanelStyle, maxWidth: "18vw"}}>
							<h3 style={{fontSize: "1rem"}}>Verdeling over fases</h3>
							{/* <CheckTable /> */}
							<MeasureManagement
								updateId={updateId}
								measures={measures}
								computationType="energy_saving_computations"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MeasuresSection;
