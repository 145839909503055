// src/screens/portfolio-screen/components/ProductTimes.tsx

import React, { useState } from "react";
import { Select, MenuItem, Button, TextField } from "@mui/material";
import { ProductTimes as ProductTimesType, ProductVersion , Product_group, ProductTimesFieldConfig } from "../types/ProductInterface";
import { createDoc, removeDoc , mergeDoc } from "../../../services/firebaseFunctions";
import { auth } from "../../../config/firebase";
import {formatDate} from "../config/tarifConfig"
import {postPortfolioData} from "../../../services/apiFunctions"

interface ProductTimesProps {
    allProductTimes: ProductTimesType[];
    selectedVersion: ProductVersion | null;
    setAllProductTimes: (value: ProductTimesType[]) => void;
    selectedProduct: Product_group | null;
    productTimesFieldConfigs: ProductTimesFieldConfig[]
}

const ProductTimesComponent: React.FC<ProductTimesProps> = ({
    selectedProduct,
    allProductTimes,
    selectedVersion,
    setAllProductTimes,
    productTimesFieldConfigs,
}) => {
    const [selectedTime, setSelectedTime] = useState<ProductTimesType | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedTime, setEditedTime] = useState<ProductTimesType | null>(null);
    const [selectedTimeId, setSelectedTimeId] = useState<string | null>(null);
    const editableFields = productTimesFieldConfigs.filter((config) => !config.readonly);

    

    const handleCreateTime = async () => {
        if (!selectedVersion || !selectedProduct) {
            console.error("No version or product selected.");
            return;
        }
    
        // Filter product times to match the selected version and prefix
        const filteredProductTimes = allProductTimes.filter(
            (time) =>
                time.version_number === selectedVersion.product_group_version_number &&
                time.prefix === selectedProduct.prefix
        );
    
        // Determine the maximum sequence number
        const maxSequenceNumber =
            filteredProductTimes.length > 0
                ? Math.max(...filteredProductTimes.map((time) => time.sequence_number))
                : 0;
    
        // Generate a new sequence number
        const newSequenceNumber = maxSequenceNumber + 1;
        const customId = `ER_${selectedVersion.product_group_version_number}.${newSequenceNumber}`;
        console.log(`New sequence number: ${newSequenceNumber}`);
    
        // Create a new product time object
        const newProductTime: Omit<ProductTimesType, "id"> = {
            created_by: auth.currentUser?.displayName || "Unknown User",
            created_on: formatDate(new Date()),
            id_old: "",
            min_erp: null,
            min_ers: null,
            modified_by: auth.currentUser?.displayName || "Unknown User",
            modified_on: formatDate(new Date()),
            name: "New Product Time",
            owner: auth.currentUser?.displayName || "Unknown User",
            prefix: selectedProduct.prefix || "DefaultPrefix",
            sequence_number: newSequenceNumber,
            version_number: selectedVersion.product_group_version_number || "DefaultVersion",
        };
    
        const apiPayload = {
            ...newProductTime,
            rowkey: customId,
            collection: 'product_times',
            action: "created",
        };
    
        try {
            // Save the new product time to the database
            const createdDocRef = await createDoc("product_times", newProductTime, customId);
    
            if (!createdDocRef) {
                throw new Error("Failed to create Product Time.");
            }

            const apiResponse = await postPortfolioData(apiPayload);
            console.log("API Response:", apiResponse);
    
            // Add the new product time to the state
            const newTimeWithId = { ...newProductTime, id: customId };
            setAllProductTimes([...allProductTimes, newTimeWithId]);
            setSelectedTime(newTimeWithId);
            setSelectedTimeId(customId);
            setIsEditing(true);
            setEditedTime(newTimeWithId);
    
            console.log("New Product Time created successfully:", newTimeWithId);
        } catch (error) {
            console.error("Failed to create Product Time:", error);
        }
    };
    

    const handleDeleteTime = async () => {
        if (selectedTime) {
            const confirmDelete = window.confirm("Are you sure you want to delete this Product Time?");
            if (confirmDelete) {
                await removeDoc("product_times", selectedTime.id);
                const apiPayload = {...selectedTime, rowkey: selectedTime.id, collection: "product_times", action: "deleted"};
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                setAllProductTimes(allProductTimes.filter((time) => time.id !== selectedTime.id));
                setSelectedTime(null);
            }
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
        setEditedTime(selectedTime);
    };

    const handleSaveTime = async () => {
        if (editedTime) {
            console.log("Saving edited product time:", editedTime);
            
            // Add `modified_on` and `modified_by` fields
            const { id, ...timeDataWithoutId } = editedTime;
            const updatedTime = {
                ...timeDataWithoutId,
                modified_on: formatDate(new Date()), // Current date and time
                modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
            };

            const apiPayload = {...updatedTime, rowkey: id, collection: "product_times", action: "updated"};
    
            try {
                // Save the updated time to Firestore
                await mergeDoc("product_times", id, updatedTime);

                // Send updated product group data to the API
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
    
                // Update local state with the saved time
                setAllProductTimes(
                    allProductTimes.map((time) =>
                        time.id === id ? { ...time, ...updatedTime } : time
                    )
                );
    
                setSelectedTime({ ...editedTime, ...updatedTime }); // Update the selected time
                setIsEditing(false); // Exit edit mode
    
                console.log("Time saved successfully:", updatedTime);
            } catch (error) {
                console.error("Failed to save time:", error);
            }
        } else {
            console.error("No time entry selected to save.");
        }
    };
    


    return (
        <div>
            

            {/* Dropdown to Select Product Time */}
            <Select
                value={selectedTime?.id || ""}
                onChange={(e) => {
                    const timeId = e.target.value;
                    const selected = allProductTimes.find((time) => time.id === timeId);
                    setSelectedTime(selected || null);
                }}
                displayEmpty
                fullWidth
                style={{ marginBottom: "20px" }}
            >
                <MenuItem value="" disabled>
                    Select a Product Time
                </MenuItem>
                {allProductTimes
                    .filter(
                        (time) =>
                            time.version_number === selectedVersion?.product_group_version_number &&
                            time.prefix === selectedProduct?.prefix
                    )
                    .map((time) => (
                        <MenuItem key={time.id} value={time.id}>
                            #{time.sequence_number}: {time.name}
                        </MenuItem>
                    ))}
            </Select>

            {/* Buttons */}
            <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
                <Button variant="contained" color="secondary" onClick={handleCreateTime}>
                    Create New
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteTime}
                    disabled={!selectedTime}
                >
                    Delete
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleEditToggle}
                    disabled={!selectedTime}
                >
                    {isEditing ? "Cancel Edit" : "Edit"}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveTime}
                    disabled={!isEditing || !editedTime}
                >
                    Save
                </Button>
            </div>

            {/* Selected Product Time Details */}
            {selectedTime && !isEditing && (
                <div>
                    {editableFields.map((config) => (
                        <p key={config.key} style={{ margin: "5px 0" }}>
                            <strong>{config.label}:</strong> {selectedTime[config.key] ?? "N/A"}
                        </p>
                    ))}
                </div>
            )}

            {/* Edit Mode */}
            {isEditing && editedTime && (
                <div>
                    {editableFields.map((config) => (
                        <TextField
                            key={config.key}
                            label={config.label}
                            type={config.type}
                            value={editedTime[config.key] ?? ""}
                            onChange={(e) =>
                                setEditedTime({
                                    ...editedTime,
                                    [config.key]:
                                        config.type === "number"
                                            ? parseFloat(e.target.value)
                                            : e.target.value,
                                })
                            }
                            fullWidth
                            margin="normal"
                            disabled={config.readonly}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProductTimesComponent;
