import React, { useEffect, useState } from "react";
import {Box,
        Typography, 
        FormControlLabel, 
        CircularProgress,
        Switch, Chip,
        Accordion, AccordionSummary, AccordionDetails} from "@mui/material";
        import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { auth } from "../../config/firebase";
import { fetchXeroAuthentication } from "../../services/apiFunctions";
import { useNavigate } from 'react-router-dom';
import { set } from "lodash";

interface XeroUkCheckProps {
    isUk: boolean;
    setIsUk: (value: boolean) => void;
    xeroToken: string | null;
    setXeroToken: (value: string) => void;
}

const XeroUkCheck: React.FC<XeroUkCheckProps> = ({
    isUk,
    setIsUk,
    xeroToken,
    setXeroToken
}) => {
    const [expanded, setExpanded] = useState(true);
    const [customClaims, setCustomClaims] = useState<{ [key: string]: boolean }>({});
	const { t } = useTranslation();
    const [userId, setUserId] = useState("");
    const [loggedIn , setLoggedIn] = useState(false);
    const [fullData, setFullData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [found, setFound] = useState(false);
    const navigate = useNavigate();

    const clientId = process.env.REACT_APP_XERO_ID;
    const redirectUrl = process.env.REACT_APP_ENV === "development" && 
                            (window.location.hostname === "127.0.0.1" || 
                            window.location.hostname === "localhost" || 
                            window.location.hostname === "0.0.0.0")
                                ? "http://localhost:3000/customer-success"
                                : process.env.REACT_APP_ENV === "development"
                                ? "https://praeter-dash-dev.web.app/customer-success"
                                : "https://dash.praeter.nl/customer-success";

    const handleOnClick = () => {
        const url = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=openid profile email accounting.transactions accounting.settings accounting.contacts accounting.attachments&state=${userId}`;
        window.location.href = url;
    }

    useEffect(() => {
        const fetchData = async () => {
            // Parse the query string to extract the 'code' and 'state'
            setLoading(true);
            const params = new URLSearchParams(window.location.search);
            const authCode = params.get('code');
            const state = params.get('state');

            if (authCode) {
                console.log('Authorization Code:', authCode);
                
                // Make your API call with the authCode
                const token = await fetchAccessToken(authCode);
                if (token.error){
                    setLoading(false);
                    console.log('Error:', token.error);
                }
                else if (token.access_token){
                    setLoading(false);
                    setFound(true);
                    setXeroToken(token.access_token);
                    setFullData(token);
                }
        
            } else {
                // Handle the case where the code is missing (e.g., error occurred)
                setLoading(false);
                console.log('Authorization code missing');
            }
        };

        fetchData();
    }, [navigate]);

    const fetchAccessToken = async (code: string) => {
        try {
            const result = await fetchXeroAuthentication(code);
            if (result.data){
                return result.data;
            }
            else{
                return {error: 'Error fetching access token'};
            }
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };

    useEffect(() => {
        if (xeroToken) {
            console.log("Found access token!");
            setIsUk(true);
            setLoggedIn(true);
            setExpanded(false);
            setFound(true);
        }
    }, [xeroToken, isUk]);

    useEffect(() => {
		const fetchCustomClaims = async () => {
			if (auth.currentUser) {
				try {
					// Fetch the custom claims
					const idTokenResult = await auth.currentUser.getIdTokenResult();
					const customClaims = idTokenResult.claims;
					setCustomClaims(customClaims); // All claims including custom ones
					if (customClaims) {
                        setUserId(customClaims.email);
                    }
				} catch (error) {
					console.error("Error fetching custom claims", error);
				}
			} else {
				console.log("No user is currently signed in.");
			}
		};
		fetchCustomClaims();
	}, []);
	
	return (
		<Box sx={{ borderRadius: "5px"}}>
            <Accordion
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			style={{
				fontSize: "0.7rem",
				backgroundColor: "white",
				fontFamily: "Basic",
				color: "#595959",
				flex: 1,
				padding: "5px",
				borderRadius: "5px",
				marginBottom: "10px",
				overflow: "auto",
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
			}}
		    >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography style={{fontFamily: "basic", fontWeight: "bold"}}>
                    {t("xero_uk_check")}
                </Typography>
                <Chip
                    label={isUk ? t("uk_yes") : t("uk_no")}
                    color={isUk ? "default" : "default"}
                    size="medium"
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                />
                <Chip
                    label={found ? t("xero_found") : t("xero_not_found")}
                    color={found ? "default" : "default"}
                    size="medium"
                    style={{ marginLeft: "10px", fontWeight: "bold" }}
                />
            </AccordionSummary>
            <AccordionDetails>
                <div className="split_div_notcent">
                    <div>
                        {<span className="deal_text"> {t("estimate_uk")} </span>}
                        <FormControlLabel 
                        control = {<Switch checked={isUk} 
                                    onChange={(e) => setIsUk(e.target.checked)} 
                                    color="primary"/>
                                    } 
                        label={isUk ? t("uk_yes") : t("uk_no")}
                        />
                    </div>
                
                {isUk && !loading && !found ? (
                <button onClick={handleOnClick} className="deal_text"> Xero Login </button>
                ) : loading ? (
                    <div className="split_div"><CircularProgress /> <label>{t("fetching_xero")}</label></div>
                ): (<span className="deal_text"></span>)}
                </div>
            </AccordionDetails>
            </Accordion>

            
			
		</Box>
	);
};

export default XeroUkCheck;
