import {
	ref,
	listAll,
	getDownloadURL,
	uploadBytes,
	deleteObject,
	StorageReference,
} from "firebase/storage";
import {storage} from "../../config/firebase";

// Function to list all files in a folder
// Function to recursively list all files in a folder, including nested subfolders
export const listFiles = async (path: string): Promise<any[]> => {
	try {
		const storageRef = ref(storage, path);

		// Recursive function to list files and subfolders
		const listAllFiles = async (folderRef: any): Promise<any[]> => {
			const result = await listAll(folderRef);
			let files = result.items;
			const subfolders = result.prefixes;

			// Recursively list files in subfolders
			for (const subfolder of subfolders) {
				const subfolderFiles = await listAllFiles(subfolder);
				files = files.concat(subfolderFiles);
			}

			return files;
		};

		const allFiles = await listAllFiles(storageRef);
		return allFiles;
	} catch (error) {
		console.error("Error listing files", error);
		return [];
	}
};

// Function to remove a folder and all its contents
export const removeFolder = async (path: string): Promise<boolean> => {
	try {
		// Get a reference to the folder
		const items = await listFiles(
			`gs://${process.env.REACT_APP_STORAGE_BUCKET}${path}/`
		);

		const deletionPromises = items.map(async (itemRef: any) => {
			// Log the document (item) being deleted
			console.log(`Deleting item: ${itemRef.fullPath}`);
			// Delete the item
			await deleteObject(itemRef);
		});

		// Wait for all deletion operations to complete
		await Promise.all(deletionPromises);
		console.log("Folder contents removed successfully");

		// Note: There is no need to delete the folder reference itself
		// Firebase Storage does not support folder deletion since folders are just paths
		return true;
	} catch (error) {
		console.error("Error removing folder:", error);
		return false;
	}
};

// Function to copy a folder to another path
export const copyFolder = async (
	sourcePath: string,
	destinationPath: string
) => {
	try {
		// List all files in the source folder
		// const sourceRef = ref(storage, sourcePath);
		const fileList = await listFiles(sourcePath);

		// Copy each file to the destination folder
		for (const file of fileList) {
			const fileName = file.name.split("/").pop(); // Get the file name
			const sourceFileRef = ref(storage, `${sourcePath}/${fileName}`);
			const destFileRef = ref(storage, `${destinationPath}/${fileName}`);

			// Get the download URL of the source file
			const imageURL = await getImageDownloadURL(sourceFileRef);
			if (!imageURL) {
				console.error(`Error getting download URL of ${fileName}`);
				continue; // Skip this file and proceed with the next one
			}

			// Download the file
			const response = await fetch(imageURL);
			const blob = await response.blob();

			// Upload the file to the destination folder
			await uploadBytes(destFileRef, blob);
			console.log(`Copied ${fileName} to ${destinationPath}`);
		}

		console.log("Folder copied successfully");
		return true;
	} catch (error) {
		console.log("Folder not copied", error);
		return false;
	}
};

// Function to upload an image
export const uploadImage = async (
	path: string,
	selectedImage: File,
	blob: Blob
) => {
	if (!selectedImage) {
		console.log("No image selected");
		return;
	}
	console.log("Uploading image...");
	const storageRef = ref(storage, path);
	const imageRef = ref(storageRef, selectedImage.name);
	try {
		console.log("Uploading file...");
		await uploadBytes(imageRef, blob);
		console.log("Uploaded a blob or file!");
		return true;
	} catch (error) {
		console.error("Error uploading file", error);
		return false;
	}
};

// Function to list all images in a folder
export const listImages = async (path: string) => {
	try {
		const storageRef = ref(storage, path);
		const res = await listAll(storageRef);
		return res.items;
	} catch (error) {
		console.error("Error listing files", error);
		return [];
	}
};

// Function to get download URL of an image
export const getImageDownloadURL = async (imageRef: StorageReference) => {
	try {
		return await getDownloadURL(imageRef);
	} catch (error) {
		console.error("Error getting download URL", error);
		return null;
	}
};

// Function to copy an image to another path
export const copyImage = async (
	sourcePath: string,
	destinationPath: string,
	imageName: string
) => {
	const sourceRef = ref(storage, `${sourcePath}/${imageName}`);
	const destRef = ref(storage, `${destinationPath}/${imageName}`);
	try {
		// Get the download URL of the source image
		const imageURL = await getImageDownloadURL(sourceRef);
		if (!imageURL) {
			console.error("Error getting download URL of the source image");
			return false;
		}

		// Download the image
		const response = await fetch(imageURL);
		const blob = await response.blob();

		// Upload the image to the destination path
		await uploadBytes(destRef, blob);

		console.log("Image copied successfully");
		return true;
	} catch (error) {
		console.error("Error copying image", error);
		return false;
	}
};

// Function to remove an image
export const removeImage = async (path: string, imageName: string) => {
	const imageRef = ref(storage, `${path}/${imageName}`);
	try {
		await deleteObject(imageRef);
		console.log("Image removed successfully");
		return true;
	} catch (error) {
		console.error("Error removing image", error);
		return false;
	}
};

// Function to download an image
export const downloadImage = async (imageURL: string) => {
	try {
		const response = await fetch(imageURL);
		const blob = await response.blob();
		const url = URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = url;
		a.download = "image.jpg"; // Change the filename if needed
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
		console.log("Image downloaded successfully");
		return true;
	} catch (error) {
		console.error("Error downloading image", error);
		return false;
	}
};
