import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import {styles} from "../../components/utils/Styles";
import {useTranslation} from "react-i18next";
interface RowData {
	[key: string]: any;
}

interface SimpleMaterialUITableProps {
	data: RowData[];
}

const SimpleMaterialUITable: React.FC<SimpleMaterialUITableProps> = ({
	data,
}) => {
	const {t} = useTranslation();
	const columns = Object.keys(data[0] || {})
		.sort()
		.reverse(); // Sort column names alphabetically

	return (
		<TableContainer component={Paper} style={{boxShadow: "none"}}>
			<Table>
				<TableHead>
					<TableRow>
						{columns.map((column) => (
							<TableCell
								key={column}
								style={{
									cursor: "pointer",
									...styles.labelLower,
									fontSize: "14px",
								}}
							>
								{column === "year" ? t("year") : t("production")}{" "}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, rowIndex) => (
						<TableRow key={rowIndex}>
							{columns.map((column, colIndex) => (
								<TableCell
									key={colIndex}
									style={{
										cursor: "pointer",
										padding: "4px",
										fontFamily: styles.summaryValueLower.fontFamily,
										fontWeight: "bold",
										textAlign: "center",
										color: styles.summaryValueLower.color,
										fontSize: "14px",
									}}
								>
									{row[column]}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default SimpleMaterialUITable;
