import React, {useEffect} from "react";
import {mergeDoc} from "../../services/firebaseFunctions";
import {styles} from "../../components/utils/Styles";
import TextSpec from "../../components/input-text";

interface ReportPrepProps {
	updateId: string;
	pageReady: boolean;
	generalData: any; // Add clientId prop here
	template: any;
	// Add clientId prop here
}

const EnergyScreeningReportPrep: React.FC<ReportPrepProps> = ({
	updateId,
	pageReady,
	template,
}) => {
	useEffect(() => {
		let status = true;
		if (!(status === pageReady)) {
			mergeDoc("energy_screening_computations", updateId, {
				progress: {
					1: {
						done: status,
						name: "Schouwnotities",
					},
				},
			});
		}
	}, [updateId, pageReady]);

	return (
		<div style={{minWidth: "70vw", overflowY: "auto"}}>
			<div style={{...styles.rightPanelStyle, minHeight: "90vh"}}>
				<h3 style={{fontSize: "1rem"}}>Schouwnotities</h3>
				<em>
					Hier kan heel kort de status + waarneming en wat het zou moeten
					worden. Dus bijvoorbeeld. Momenteel heeft het pand nog geen
					ledverlichting. Het is raadzaam dit direct te installeren bij
					verlichting die gemiddeld meer dan een uur brandt. De rest kan op een
					natuurlijk moment.
				</em>
				<p></p>
				<TextSpec
					updateId={updateId}
					templateUnfiltered={template}
					filter={"schouw_notes"}
					computationType={"energy_screening_computations"}
				/>
			</div>
		</div>
	);
};
export default EnergyScreeningReportPrep;
