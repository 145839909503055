import React, { useEffect, useState, useCallback } from "react";
import { mergeDoc } from "../../services/firebaseFunctions";
import renderInputFields from "../utils/renderInputFields";
import { useTranslation } from "react-i18next";
import { Template, FormData } from "../../types";
import { use } from "i18next";

const InputSpec: React.FC<{
  updateId: string;
  interactionId: string;
  interactionData: any;
  filter: string;
  templateUnfiltered: Template;
  computationType: string;
}> = ({
  updateId,
  interactionId,
  interactionData,
  filter,
  templateUnfiltered,
  computationType,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [change, setChange] = useState<boolean>(false);
  const docId = interactionId;
  const path = `${computationType}/${updateId}/computation_inputs`;

  const fetchFormData = useCallback(async (template: Template) => {
    try {
      const computationDataAll = await interactionData.find(
        (interaction: any) => interaction.id === interactionId
      );
      const computationDataFields = computationDataAll?.fields || {};
      setFormData(computationDataFields);
      const filteredRecords: Template = {};

      Object.keys(template).forEach((key) => {
        const record = template[key];
        if (
          record.input_categories &&
          record.input_categories.includes(filter)
        ) {
          filteredRecords[key] = record;
        }
      });

    } catch (error) {
      console.error("Error fetching computation data:", error);
      setFormData({});
    }
  }, [interactionData, interactionId, filter]);

  useEffect(() => {
    if (change === true && Object.keys(formData).length > 0) {
      mergeDoc(path, docId, {
        fields: formData,
        computation_present: false,
      });
      setChange(false);
    }
  }, [change, formData, path, docId]);

  useEffect(() => {
    const fetchData = async () => {
      if (loaded === false) {
        try {
          setLoading(true);
          await fetchFormData(templateUnfiltered);
        } catch (error) {
          console.error("Error fetching template: ", error);
        } finally {
          setLoading(false);
          setLoaded(true);
        }
      }
    };

    fetchData();
  }, [fetchFormData, templateUnfiltered, loaded]);

  useEffect(() => {
    setLoaded(false);
  }, [interactionId, updateId, templateUnfiltered]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div
            style={{
              position: "relative",
              maxHeight: "90vh",
              maxWidth: "300px",
            }}
          >
            {
              <>
                <div>
                  {renderInputFields({
                    template: templateUnfiltered,
                    formData,
                    setFormData,
                    setChange,
                    t,
                    filter,
                  })}
                </div>
              </>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default InputSpec;
