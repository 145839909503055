import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {styles} from "../../components/utils/Styles";
import {t} from "i18next";

interface Option{
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: string | number;
}

const MWASelectorPanel: React.FC<{
	selectedOption: string;
	handleChange: (event: React.ChangeEvent<{value: unknown}>) => void;
	interactionDataPV: {id: string; name: string}[];
	interactionResults: {
		inputs: {
			client_inputs: {
				[key: string]: any;
			};
		};
	} | null;
}> = ({
	selectedOption,
	handleChange,
	interactionDataPV,
	interactionResults,
}) => {

	const renderClientInputValue = (value: any) => {
		if (typeof value === "object" && value !== null && "name" in value) {
		  const option = value as Option; // Typecast the object as Option
		  const output = option.data_type === "STRING" ? option.name : option.value;
		  return output; // Prefer text_en if available
		}
		return value; // If it's not an Option, return the value as-is
	  };

	return (
		<div
			style={{
				...styles.rightPanelStyle,
				marginLeft: "10px",
				maxHeight: "90vh",
				minWidth: "300px",
				maxWidth: "300px",
				position: "relative",
				flex: 1,
			}}
		>
			<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
				{t("computationMWA")}
			</h3>
			<Select
				value={selectedOption}
				onChange={(event) => handleChange(event as any)}
				sx={{...styles.label, minWidth: "300px", maxHeight: "25px"}}
			>
				{interactionDataPV.map((option) => (
					<MenuItem key={option.id} value={option.id} sx={{...styles.label}}>
						{option.name}
					</MenuItem>
				))}
			</Select>
			<h3 style={{fontSize: "1rem", fontFamily: "Basic"}}>
				{t("computationMWAResult")}
			</h3>
			{interactionResults &&
				interactionResults.inputs &&
				Object.entries(interactionResults.inputs.client_inputs).map(
					([key, value], index) =>
						[
							"kv_gv",
							"pv_system",
							"perc_ev",
							"pv_panel_wp",
							"investor",
						].includes(key) ? (
							<div key={index}>
								<strong>{key}</strong>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "flex-start",
									}}
								>
									<span style={{textAlign: "right"}}>{renderClientInputValue(value)}</span>
								</div>
							</div>
						) : null
				)}
		</div>
	);
};

export default MWASelectorPanel;
