import {
	createDoc,
	mergeDoc,
	fetchDocsData,
	fetchDocData,
} from "../services/firebaseFunctions";

interface FetchComputationProps {
	computationType: string;
	clientId: string;
	setCRMDetails: (value: any) => void;
	setUpdateId: (id: string) => void;
	setRoomId: (id: string) => void;
	setComputationData: (data: Record<string, any>) => void;
	setShowScreens: (show: boolean) => void;
	setProgress: (progress: Record<string, any>) => void;
	navigate: (path: string) => void;
}

const fetchComputation = async ({
	computationType,
	clientId,
	setCRMDetails,
	setUpdateId,
	setRoomId,
	setComputationData,
	setShowScreens,
	setProgress,
	navigate,
}: FetchComputationProps): Promise<void> => {
	const computation = await fetchDocData(computationType, clientId);
	const computationData2 = await fetchDocData(
		`${computationType}/${clientId}/general_inputs`,
		"all"
	);
	const computationDataList = await fetchDocsData(
		`${computationType}/${clientId}/computation_inputs`
	);
	const routePart1 = computationType.split("_")[0];
	const routePart2 = computationType.split("_")[1];
	const route = routePart1 + "-" + routePart2;
	setCRMDetails(null);

	if (
		Object.keys(computation).length === 0 &&
		Object.keys(computationData2).length === 0
	) {
		setUpdateId("");
		setRoomId("");
		setComputationData({});
		setShowScreens(false);
		setProgress({});
		navigate(`/${route}/${clientId}/initialize`);
		return;
	}

	if (computationDataList && Object.keys(computationDataList).length === 0) {
		const room: any = await createDoc(`${computationType}/${clientId}/rooms`, {
			name: "All",
			name_clean: "all",
		});
		setRoomId(room.id);
		if (room?.id) {
			await mergeDoc(
				`${computationType}/${clientId}/computation_inputs`,
				room.id,
				{
					name: "Algemene berekening",
					rooms: [],
					rank: 0,
				}
			);
		}
	} else {
		const selectedComputation: any = computationDataList.filter((data: any) => {
			if (data.name === "Algemene berekening") {
				setComputationData(data);
			}
		});
		setRoomId(selectedComputation.id);
	}

	if (!(Object.keys(computationData2).length > 0)) {
		setUpdateId(clientId);
		// get the "algemene berekening" from the computation_inputs

		setRoomId("");
		setComputationData({});
		setShowScreens(true);
		navigate(`/${route}/${clientId}/initialize`);
		return;
	}

	setUpdateId(clientId);

	if (computationData2?.fields) {
		setComputationData(computationData2.fields);
	} else {
		setComputationData({});
		setProgress({});
	}
    setShowScreens(true);
};

const getDifferentKeysWithValues = (interactionData: any[]) => {
	const result: {key: string; values: any[]}[] = [];

	const keys = new Set<string>();
	interactionData.forEach((record) => {
		const fields = record.fields;
		if (fields) {
			Object.keys(fields).forEach((key) => keys.add(key));
		}
	});

	for (const key of keys) {
		const values = interactionData.map((obj) => obj.fields?.[key]);
		const firstValue = values[0];
		if (
			values.some(
				(value) => JSON.stringify(value) !== JSON.stringify(firstValue)
			)
		) {
			result.push({key, values});
		}
	}

	return result;
}

export { fetchComputation, getDifferentKeysWithValues};
