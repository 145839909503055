import React, {useState, useEffect} from "react";
import {getDownloadURL, ref} from "firebase/storage";
import {storage} from "../../config/firebase";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import {
	fetchPDFTemplate,
	saveToSharepoint,
	fetchVAReport,
} from "../../services/apiFunctions";
import {mergeDoc, fetchDocData, fetchDocsData} from "../../services/firebaseFunctions";
import {removeFolder} from "../../components/utils/s3ImageOperations";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import SaveIcon from "@mui/icons-material/Save";
import {CircularProgress, IconButton} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import GetAppIcon from "@mui/icons-material/GetApp";
import {downloadMultipleFiles} from "../../components/pdf-viewer/PDFViewer";
import {t} from "i18next";

interface InteractionData {
	id: string;
	selected?: boolean;
	name: string;
	rank: number;
	computation_present: boolean;
	pdf_present: boolean;
	fields: {
		[key: string]: any; // Define the fields' types properly
	};
}

interface GeneralData {
	id: string;
	report_link: string;
	fields: {
		eLL3U03GcWipjoW0DkzU: string;
		QQCT34RNyYnuu1UdpV9j: string;
		UY3UR611KOx9pSjxNlu6: string;
		s9wXjYI6ZzLfEgmy4Ow5: string;
		vatxUDBKEj3Xf6k5vRtV: number;
		"0scJJYOAMFTyTltNXtNL": string;
		"5xrjtBgD8t4DQsKCjOwW": string;
		sFPN0DboUTDB8oJr90St: string;
		ISw12WIvpFiTv5YBr8iU: string;
		YfRokLRi9yJOY7TMKo0k: string;
	};
	report_ready: boolean;
}

interface ContentProps {
	updateId: string;
	selectedInteraction: string;
	interactionData: InteractionData[];
	generalData: GeneralData[];
}

interface Option {
	id: string;
	data_type: string;
	name: string;
	name_en: string;
	text: string;
	text_en: string;
	value: number | string;
}

const getAllOptions = async () => {
	const options = await fetchDocsData("options");

	return options.map((option: any) => {
		return option; // Return each individual option object
	});
}

const Content: React.FC<ContentProps> = ({
	updateId,
	selectedInteraction,
	interactionData,
	generalData,
}) => {
	const [downloadURL, setDownloadURL] = useState("");
	const [downloadURLDocx, setDownloadURLDocx] = useState("");

	const [scale, setScale] = useState(1.5);
	const [loadingReport, setLoadingReport] = useState(false);
	const [savingFile, setSavingFile] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		interactionData.map(async (interaction) => {
			if (
				interaction.computation_present === true &&
				interaction.pdf_present === false
			) {
				await fetchPDFTemplate(
					updateId,
					interaction.id,
					"solar_rental_computations"
				);

				await mergeDoc(
					`solar_rental_computations/${updateId}/computation_inputs`,
					`${interaction.id}`,
					{computation_present: true, pdf_present: true}
				);
			}
		});
	}, [interactionData, updateId]);

	useEffect(() => {
		let newUrl = "";
		newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_rental_computations/${updateId}/all/output/report.pdf`;
		// if (selectedInteraction === "report") {
		// 	newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_rental_computations/${updateId}/all/output/report.pdf`;
		// } else {
		// 	newUrl = `gs://${process.env.REACT_APP_STORAGE_BUCKET}/solar_rental_computations/${updateId}/${selectedInteraction}/output/output_basic.pdf`;
		// }

		const fetchDownloadURL = async () => {
			try {
				const download = await getDownloadURL(ref(storage, newUrl));
				setDownloadURL(download);
				const downloadDocx = await getDownloadURL(
					ref(storage, newUrl.replace(".pdf", ".docx"))
				);
				setDownloadURLDocx(downloadDocx);
			} catch (error) {
				console.log("Document not found:", error);
				setDownloadURL("");
			}
		};

		fetchDownloadURL();
	}, [selectedInteraction, loadingReport, updateId]);

	const handleSaveAction = async () => {
		setSavingFile(true);
		let pathPrefix = "02 Energieke Regio";
		if (generalData[0].fields?.eLL3U03GcWipjoW0DkzU === "Praeter") {
			pathPrefix = "01 Praeter";
		}
		const companyId = generalData[0].fields?.["5xrjtBgD8t4DQsKCjOwW"];
		const companyName = generalData[0].fields?.["YfRokLRi9yJOY7TMKo0k"];
		const cleanedCompanyName = companyName.replace(/[^a-zA-Z0-9\s]/g, "");
		const pathPrefixShort = pathPrefix === "01 Praeter" ? "PR" : "ER";
		const filesToSave = ["report.pdf", "report.docx"];

		Promise.all(
			filesToSave.map((file) =>
				saveToSharepoint(
					updateId,
					"solar_rental_computations",
					"all",
					`${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.VA/${updateId}`,
					file
				)
			)
		)
			.then(() => {
				console.log("saved");
			})
			.catch((error) => {
				console.error("Failed to save files:", error);
			})
			.finally(() => {
				console.log(
					`https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.VA/${updateId}`
				);
				mergeDoc(
					`solar_rental_computations/${updateId}/general_inputs`,
					"all",
					{
						report_link: `https://praeter.sharepoint.com/Gedeelde documenten/02 Klanten/${pathPrefix}/${pathPrefixShort}_${companyId}.${cleanedCompanyName}/MWA.VA/${updateId}`,
					}
				);
				setSavingFile(false); // This ensures it's set to false regardless of success or failure
			});
	};

	const formatClientInputs = (clientInputs: { [key: string]: any }, options: Option[]) => {
		if (Object.keys(clientInputs).length > 0 && options.length > 0) {
				const updatedFields = Object.entries(clientInputs).reduce(
					(acc: { [key: string]: any }, [fieldId, fieldValue]) => {
						const matchingOption = options.find(
							(option) => option.id === fieldValue
						);
						if (matchingOption) {
							acc[fieldId] = matchingOption; 
						} else {
							acc[fieldId] = fieldValue; 
						}
	
						return acc;
					},
					{}
				);
				return updatedFields;
			}
		return clientInputs;
	};

	useEffect(() => {
		const fetchData = async () => {
			if (
				generalData &&
				generalData[0]?.fields &&
				generalData[0]?.report_ready !== true &&
				loadingReport === false &&
				interactionData.filter((item) => item.computation_present === false)
					.length === 0 &&
				interactionData.filter((item) => item.name !== "Algemene berekening")
					.length > 0 &&
				error === false
			) {
				try {
					await removeFolder(
						`/solar_rental_computations/${updateId}/all/output`
					);
				} catch {
					console.log("Folder not found");
				}
				const allDataFields: any = {};
				const allData: any = generalData[0];

				for (const key in allData.fields) {
					if (allData.fields.hasOwnProperty(key)) {
						const fieldValue = allData.fields[key];
						try {
							const docData = await fetchDocData(`client_inputs`, key);
							if (docData) {
								allDataFields[docData.name_clean] = fieldValue;
								if (docData.options.length > 0) {
								}
							} else {
								console.log(`Document with key ${key} not found.`);
							}
						} catch (error) {
							console.error(`Error fetching document with key ${key}:`, error);
						}
					}
				}
				setLoadingReport(true);
				let sortedData = interactionData.filter(
					(item) => !item.name.includes("MWA")
				);

				sortedData = sortedData.sort((a, b) => {
					const aValue = parseFloat(a.fields?.["TQ5rMwQ6srDuTuZCM5Cy"]);
					const bValue = parseFloat(b.fields?.["TQ5rMwQ6srDuTuZCM5Cy"]);

					if (!isNaN(aValue) && !isNaN(bValue)) {
						return aValue - bValue; // Sorting in ascending order
					}
					return 0;
				});
				const payload = {
					...allDataFields,
					owner_computations: sortedData
						.filter(
							(item) => item.fields?.["PO2qBk43f6faOOheeQz8"] === "investor"
						)
						.map((item) => item.id),
					tenant_computations: sortedData
						.filter(
							(item) => item.fields?.["PO2qBk43f6faOOheeQz8"] === "tenant"
						)
						.map((item) => item.id),
					selected_tariff: interactionData.find((item) => item.selected === true)
						?.fields?.["TQ5rMwQ6srDuTuZCM5Cy"],
					electricity_current_tarrif: interactionData.find((item) =>
						item.name.includes("MWA")
					)?.fields?.["VXVu21OSMDROEVjGguuO"],
					solar_energy_interaction_id: interactionData.find((item) =>
						item.name.includes("MWA")
					)?.id,

					owner_energy_contract:
						generalData[0].fields?.["ISw12WIvpFiTv5YBr8iU"],
				};
				payload.solar_energy_computation_id = updateId;
				console.log("Payload", payload);
				const allOptions = await getAllOptions();
				const updatedPayload = formatClientInputs(payload, allOptions);
				const result = await fetchVAReport(updateId, updatedPayload);
				// console.log("Result", result);
				if (result.success === true) {
					console.log("Report ready");
					await fetchPDFTemplate(updateId, "all", "solar_rental_computations");
					await mergeDoc(
						`solar_rental_computations/${updateId}/general_inputs`,
						`all`,
						{
							report_ready: true,
						}
					);
					setLoadingReport(false);
				} else {
					setLoadingReport(true);
					setError(true);
				}
			}
		};

		fetchData();
	}, [interactionData, generalData, loadingReport, updateId, error]);

	const handleZoomIn = () => {
		setScale(scale + 0.1);
	};

	const handleZoomOut = () => {
		if (scale > 0.1) {
			setScale(scale - 0.1);
		}
	};

	return (
		<div
			style={{
				position: "relative",
				overflowY: "auto",
				maxWidth: "65vw",
				maxHeight: "95vh",
				minHeight: "95vh",
				minWidth: "65vw",
				marginLeft: "10px",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 10,
					right: 10,
					marginRight: "10px",
					marginTop: "10px",
					zIndex: 1,
				}}
			>
				<button
					onClick={handleZoomIn}
					style={{
						color: "#267FB8",
						fontSize: "35px",
						background: "transparent",
						border: "none",
					}}
				>
					{" "}
					+{" "}
				</button>
				<button
					onClick={handleZoomOut}
					style={{
						color: "#267FB8",
						fontSize: "40px",
						background: "transparent",
						border: "none",
					}}
				>
					-{" "}
				</button>
				<a
					href={generalData[0]?.report_link}
					target="_blank"
					rel="noreferrer"
					style={{
						textDecoration: "none",
						display: "flex",
						alignItems: "center",
						color: generalData[0]?.report_link
							? "#267FB8"
							: "rgba(0, 0, 0, 0.26)",
					}}
				>
					<LinkIcon
						style={{
							fontSize: "24px",
							marginRight: "4px",
						}}
					/>
					<span style={{fontSize: "16px"}}>Link</span>
				</a>
				<IconButton
					onClick={handleSaveAction}
					disabled={savingFile || generalData[0].report_ready === false}
					style={{
						color: savingFile === true ? "white" : "#267FB8",
						fontSize: "45px",
						top: -4,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					<SaveIcon />
				</IconButton>
				<IconButton
					onClick={() => downloadMultipleFiles([downloadURL, downloadURLDocx])}
					disabled={
						isDownloading === true || generalData[0]?.report_ready === false
					}
					style={{
						color: isDownloading === true ? "white" : "#267FB8",
						fontSize: "45px",
						top: 28,
						right: 60,
						position: "absolute",
						background: "transparent",
						border: "none",
						marginLeft: "10px",
						marginTop: "10px",
					}}
				>
					<GetAppIcon />
				</IconButton>
			</div>
			{loadingReport === true && ["report"].includes(selectedInteraction) && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<h6
						style={{
							fontSize: "0.9rem",
							fontFamily: "Barlow",
							marginTop: "150px",
						}}
					>
						{t("computationLoading")}
					</h6>
					<CircularProgress />
				</div>
			)}
			{!(loadingReport === true && ["report"].includes(selectedInteraction)) &&
				downloadURL && <PDFViewer downloadURL={downloadURL} scale={scale} />}
		</div>
	);
};

export default Content;
