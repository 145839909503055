import React, {useEffect, useRef} from "react";
import Chart from "chart.js/auto";

interface BarPlotProps {
	data: {
		organisation_average_gas: number;
		organisation_average_electricity: number;
		sector_gas: number;
		sector_electricity: number;
	};
}

const BarPlot: React.FC<BarPlotProps> = ({data}) => {
	const chartRef = useRef<HTMLCanvasElement>(null);
	const chartInstance = useRef<Chart | null>(null);

	useEffect(() => {
		const context = chartRef.current?.getContext("2d");
		if (!context) return; // Ensure context is available

		// Destroy previous chart instance if it exists
		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		// Create new chart instance
		chartInstance.current = new Chart(context, {
			type: "bar",
			data: {
				labels: ["Gasverbruik", "Elektriciteitsverbruik"],
				datasets: [
					{
						label: "Verbruik klant",
						backgroundColor: "rgba(54, 162, 235, 0.5)",
						borderColor: "rgba(54, 162, 235, 1)",
						borderWidth: 1,
						hoverBackgroundColor: "rgba(54, 162, 235, 0.8)",
						hoverBorderColor: "rgba(54, 162, 235, 1)",
						data: [
							data.organisation_average_gas,
							data.organisation_average_electricity,
						],
					},
					{
						label: "Sector gemiddelde",
						backgroundColor: "rgba(255, 159, 64, 0.5)",
						borderColor: "rgba(255, 159, 64, 1)",
						borderWidth: 1,
						hoverBackgroundColor: "rgba(255, 159, 64, 0.8)",
						hoverBorderColor: "rgba(255, 159, 64, 1)",
						data: [data.sector_gas, data.sector_electricity],
					},
				],
			},
			options: {
				indexAxis: "x",
				scales: {
					x: {
						beginAtZero: true,
					},
					y: {
						stacked: false,
						beginAtZero: true,
					},
				},
				plugins: {
					legend: {
						labels: {
							font: {
								family: "Karla", // Global font family
							},
						},
					},
					title: {
						display: false, // Add title here if needed
					},
				},
			},
		});

		// Cleanup on unmount
		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, [data]);

	return <canvas ref={chartRef} />;
};

export default BarPlot;
