import React, { useState, useEffect } from "react";
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Project } from "../types/ProjectInterface";
//import { projectFieldConfigs } from "../config/fieldConfigs";
import { auth } from "../../../config/firebase"; // Import for user data
import { mergeDoc, createDoc, removeDoc } from "../../../services/firebaseFunctions"; // Import backend functions
import {ProjectFieldConfig} from "../types/ProjectInterface"
import {formatDate} from "../config/tarifConfig"
import { getAllTemplates } from "../utils/fetchFunctions";
import { set } from "lodash";
import {postPortfolioData} from "../../../services/apiFunctions"



interface ProjectDetailsProps {
    project: Project | null;
    setSelectedProject: (project: Project | null) => void;
    allProjects: Project[];
    setAllProjects: (projects: Project[]) => void;
    projectFieldConfigs: ProjectFieldConfig[];
}

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
    project,
    setSelectedProject,
    allProjects,
    setAllProjects,
    projectFieldConfigs
    }) => {
    const [isEditingProject, setIsEditingProject] = useState<boolean>(false);
    const [editedProject, setEditedProject] = useState<Project | null>(null);
    const [isCreatingNewProject, setIsCreatingNewProject] = useState<boolean>(false); // For new project dialog
    const [newProjectNumber, setNewProjectNumber] = useState<string>(""); // Input for project number    

    if (!project && !isCreatingNewProject) {
        return <p>No project selected</p>;
    }

    // Handlers for editing, saving, creating, and deleting
    const handleEditProjectToggle = () => {
        setIsEditingProject(!isEditingProject);
        setEditedProject(project); // Initialize the edit state with the selected project
    };

    const handleSaveProject = async () => {
        if (editedProject) {
            console.log("Saving edited project:", editedProject);
            // Update `modified_on` and `modified_by`
            const { id, ...projectDataWithoutId } = editedProject;
            const updatedProject = {
                ...projectDataWithoutId,
                modified_on: formatDate(new Date()), // Current date and time
                modified_by: auth.currentUser?.displayName || "Unknown User", // Current user
            };

            const apiPayload = {...updatedProject, rowkey: id, collection: "project_information", action: "updated"};
    
            try {
                // Save the updated project to Firestore
                await mergeDoc("project_information", id, updatedProject); // Save to backend
                
                // Send updated product group data to the API
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                
                // Exit edit mode            
                setSelectedProject(editedProject); // Update the selected project
                setIsEditingProject(false); // Exit edit mode
                console.log("Product group saved successfully:", updatedProject);
            } catch (error) {
                console.error("Failed to save project:", error);
            }
        } else {
            console.error("No project selected to save");
        }
    };
    

    const handleCreateProject = async () => {
        if (!newProjectNumber) {
            console.error("Project number is required.");
            return;
        }
    
        // Check if a project with the same number already exists
        const projectExists = allProjects.some(
            (proj) => proj.project_number === parseInt(newProjectNumber, 10)
        );
    
        if (projectExists) {
            alert("A project with this number already exists.");
            return;
        }
    
        // Generate a custom ID
        const customId = `ER_${newProjectNumber}`;
        console.log(`Creating project with ID: ${customId}`);
    
        // Create a new project object
        const newProject: Omit<Project, "id"> = {
            name: "New Project",
            project_number: parseInt(newProjectNumber, 10),
            status: "",
            responsible_erp: "",
            responsible_ers: "",
            budget_scans: null,
            total_budget: null,
            scans_number: null,
            description: "",
            declaration: "",
            billing_to_client: "",
            green_deal: false,
            marketing: "",
            time_distribution: "",
            condition: "",
            owner: auth.currentUser?.displayName || "Unknown User",
            modified_by: auth.currentUser?.displayName || "Unknown User",
            created_on: formatDate(new Date()),
            modified_on: formatDate(new Date()),
            ready: false,
            prefix: "ER", // Default prefix
            client: "",
            sales_tarrif: 130,
            erp_tarrif: 116,
            ers_tarrif: 104,
        };
    
        const apiPayload = {
            ...newProject,
            rowkey: customId,
            collection: "project_information",
            action: "created",
        };
    
        try {
            // Save the new project to the database
            const createdDocRef = await createDoc("project_information", newProject, customId);
    
            if (!createdDocRef) {
                throw new Error("Failed to create project.");
            }

            const apiResponse = await postPortfolioData(apiPayload);
            console.log("API Response:", apiResponse);
    
            // Add the new project to the state
            const newProjectWithId = { ...newProject, id: customId };
            setAllProjects([...allProjects, newProjectWithId]);
            setSelectedProject(newProjectWithId);
            setIsEditingProject(true);
            setEditedProject(newProjectWithId);
            setIsCreatingNewProject(false); // Close the dialog
    
            console.log("New project created successfully:", newProjectWithId);
        } catch (error) {
            console.error("Failed to create project:", error);
        }
    };
    

    const handleDeleteProject = async () => {
        if (project) {
            const confirmDelete = window.confirm("Are you sure you want to delete this project?");
            if (confirmDelete) {
                await removeDoc("project_information", project.id); // Delete from backend
                const apiPayload = {...project, rowkey: project.id, collection: "project_information", action: "deleted"};
                const apiResponse = await postPortfolioData(apiPayload);
                console.log("API Response:", apiResponse);
                setAllProjects(allProjects.filter((p) => p.id !== project.id)); // Remove from state
                setSelectedProject(null); // Deselect
                setIsEditingProject(false); // Exit edit mode
            }
        }
    };

    const handleProjectInputChange = (field: keyof Project, value: any) => {
        if (editedProject) {
            setEditedProject({ ...editedProject, [field]: value }); // Update the edited project
        }
    };

    const editableFields = projectFieldConfigs.filter((config) => !config.readonly);

    return (
        <div>
            {/* Action Buttons */}
            <div style={{ marginBottom: "20px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditProjectToggle}
                >
                    {isEditingProject ? "Cancel Edit" : "Edit"}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveProject}
                    disabled={!isEditingProject}
                    style={{ marginLeft: "10px" }}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setIsCreatingNewProject(true)}
                    style={{ marginLeft: "10px" }}
                >
                    Create New
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteProject}
                    style={{ marginLeft: "10px" }}
                >
                    Delete
                </Button>
            </div>

            {/* Editable Fields */}
            {isEditingProject ? (
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr", // Three columns
                        gap: "20px",
                        marginTop: "20px",
                    }}
                >
                    {editableFields.map((config) => (
                        <TextField
                            key={config.key}
                            label={config.label}
                            type={config.type}
                            value={editedProject ? editedProject[config.key] ?? "" : ""}
                            onChange={(e) => handleProjectInputChange(config.key, e.target.value)}
                            fullWidth
                            margin="normal"
                            disabled={config.readonly}
                        />
                    ))}
                </div>
            ) : (
                // Non-editable Fields in Three Columns
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gap: "20px",
                        marginTop: "20px",
                    }}
                >
                    {editableFields.map((config) => (
                        <div key={config.key}>
                            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                                {config.label}:
                            </Typography>
                            <Typography variant="body1">
                                {project ? project[config.key] ?? "N/A" : "N/A"}
                            </Typography>
                        </div>
                    ))}
                </div>
            )}

            {/* Dialog for Creating a New Project */}
            <Dialog open={isCreatingNewProject} onClose={() => setIsCreatingNewProject(false)}>
                <DialogTitle>Enter New Project Number</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Project Number"
                        type="number"
                        fullWidth
                        value={newProjectNumber}
                        onChange={(e) => setNewProjectNumber(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCreatingNewProject(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateProject} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ProjectDetails;
