export const clientInputsColumns = {
	simultaneous_days: "lVruS850hFiEjf2vAHao",
	usage_input_type: "XygNtUDvVNRMT1AnTC3A",
	general_city: "UY3UR611KOx9pSjxNlu6",
	general_company_name: "YfRokLRi9yJOY7TMKo0k",
	general_street: "sFPN0DboUTDB8oJr90St",
	general_house_number: "81p2SqnpKL8N0XaXzb1j",
	general_email: "sfTawMuK26GQXWtfjLcv",
	general_first_name: "dq6eFy6WMh75LGoOTXDy",
	general_last_name: "s9wXjYI6ZzLfEgmy4Ow5",
	pipedrive_company_id: "5xrjtBgD8t4DQsKCjOwW",
	production_year_1: "5m50idGbxXVinxyCqxQx",
	pv_panel_wp: "7tMOFAmxA926fCMQi96X",
	pv_simultaneous_percentage: "9UFkhbqlhCGpHNiteEeA",
	perc_ev: "KLrpXGk9fWGtbdgMQnwf",
	investor: "R4LRyZTy60wI8MHKa3wQ",
	pv_system: "RzwteDVIolc5rtz1kwZI",
	roof_type: "YZD9APtUiugNC2zJCsBU",
	delivery_net_type: "cqnU6DxcjAb548qDKU2Z",
	kv_gv: "fwceCp2oXft0jHzJlJxu",
	production_input_type: "juVY4NRKJwVnhrwHuNLa",
	electricity_current_tarrif_type: "mED5tkkNTEplL34gmKZq",
	pv_count: "vatxUDBKEj3Xf6k5vRtV",
	project_country: "QQCT34RNyYnuu1UdpV9j",
	project_layout: "eLL3U03GcWipjoW0DkzU",
	grid_congestion_text: "mpRm35hRyWzqWFVMclxS",
	quick_calculation_id: "fbzu2wmWI3MdG2BcWnIs",
	net_rental_costs: "yux9lU13jUdUSFE75v6l",
	delivery_net_tariff: "gpMr0m3HqfXwUt1IQprX",
	electricity_current_tarrif: "U1SUCQKFxvwtKwjrKXKc",
	private_wire_perc: "sWZlhRf1UW7lrTPGUMZZ",
	pv_sde_type: "URamJNvx8KTJfFnCihxD",
	pv_simultaneous_days: "lVruS850hFiEjf2vAHao",
	pv_simultaneous_input: "XygNtUDvVNRMT1AnTC3A",
	general_date_visit: "5lC7RUNxnuMuLCfIDFPr",
	project_name: "YrQl2PAIyd809ekKWBhD",
	private_wire_tarriff: "U1SUCQKFxvwtKwjrKXKc",
	pv_sde_value: "ROHWZ06YByQo3pZLnlli",
	general_housenumber: "0scJJYOAMFTyTltNXtNL",
	electricity_usage: "HCYFCWj7cfFJGmrV73Od",
	general_name_advisor: "BRK00c57Yt7YnIiEkSTc",
	// "pv_simultaneous_percentage": "9UFkhbqlhCGpHNiteEeA",
	// 'rc_facade_current',
	// 'unit_heating_cv_current',
	// 'unit_water_electric_boiler',
	// 'rc_facade_desired',
	// 'solar_computation',
	// 'general_room_name',
	// 'Sector category',
	// 'general_name_adviseur',

	// 'pv_sde_type',
	// 'cvir_reduction_degree',
	// 'ir_isolation_lvl',
	// 'rc_roof_current',
	// 'rc_facade_desired',
	// 'unit_isolation_floor',
	// 'rc_floor_current',
	// 'general_postal_code',
	// 'unit_ventilation_wtw',
	// 'ir_watt_type',
	// 'pv_sde_type': "URamJNvx8KTJfFnCihxD",
	// 'building_category',
	// 'unit_heating_underfloor',
	// 'rc_roof_desired',
	// 'window_type',
	// 'unit_lighting_fitting_change',
	// 'pv_simultaneous_input': "XygNtUDvVNRMT1AnTC3A",
	// 'general_date_visit': "5lC7RUNxnuMuLCfIDFPr",
	// 'unit_isolation_facade',
	// 'unit_heating_infra',
	// 'rc_floor_desired',
	// 'water_m3',
	// 'unit_water_heater',
	// 'building_m2',
	// 'building_year',
	// 'unit_isolation_roof',
	// 'project_name': "YrQl2PAIyd809ekKWBhD",
	// 'electricity_outlet',
	// 'private_wire_tarriff': "U1SUCQKFxvwtKwjrKXKc",
	// 'pv_sde_value',
	// 'pv_simultaneous_days':"lVruS850hFiEjf2vAHao",
	// 'new_window_type',
	// 'height_m',
	// 'unit_heating_pump_advise'
};
