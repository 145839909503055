import React, { useEffect, useState } from "react";
import { Project, ProjectProduct } from "../types/ProjectInterface";


interface RelatedProductsListProps {
    project: Project; // Selected project
    allProjectsProducts: ProjectProduct[]; // All project-product relationships
    setAllProjectsProducts: (products: ProjectProduct[]) => void; // Setter for all products
    onSelectProjectProduct: (product: ProjectProduct | null) => void; // Callback to handle product selection
    selectedProjectProduct: ProjectProduct | null; // Currently selected product
}

const RelatedProductsList: React.FC<RelatedProductsListProps> = ({
    project,
    allProjectsProducts,
    setAllProjectsProducts,
    onSelectProjectProduct,
    selectedProjectProduct,
    }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedProduct, setEditedProduct] = useState<ProjectProduct | null>(null);
 

    // Filter related products for the selected project
    const relatedProducts = allProjectsProducts.filter(
        (pp) => pp.project_number === project.project_number && pp.prefix === project.prefix
    );

   
    const handleProductSelect = (product: any) => {

        onSelectProjectProduct(product); // Update the selected product in parent
        setEditedProduct(null);
        setIsEditing(false);
    };


    return (
        <div>
            <h3>Related Products</h3>

            {/* Dropdown to select a product */}
            <div style={{ display: "flex", flex: 1, paddingLeft: "px" }}>
                            {/* Left Column: Related Products List */}
                            <div style={{ flex: 1, maxWidth: "100%", paddingRight: "20px" }}>
                                {relatedProducts.map((product) => (
                                    <div
                                        key={product.id}
                                        style={{
                                            padding: "10px",
                                            backgroundColor: selectedProjectProduct?.id === product.id ? "#ddd" : "#fff",
                                            cursor: "pointer",
                                            marginBottom: "10px",
                                            border: "1px solid #ccc",
                                            borderRadius: "5px",
                                        }}
                                        onClick={() => handleProductSelect(product)}
                                    >
                                        {product.project_number}.{product.product}.{product.product_name}
                                    </div>
                                ))}
                            </div>
                        </div>
            
    
        </div>
    );
};

export default RelatedProductsList;
