import React, {useState, useEffect} from "react";
import Select from "react-select";
import {TableContainer, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {SearchOption, MultiDropdownOption} from "../../types";

const MultiDropdownTable: React.FC<{
	options: MultiDropdownOption[];
	selectedOption: MultiDropdownOption | null;
	setSelectedOption: any;
	type: "option" | "category" | "client_inputs" | "client_options" | "estimate"; // Ken op basis hiervan nog andere dingen doen met de data ofzo
}> = ({options, setSelectedOption, type}) => {
	const {t} = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState<string>("nl");
	const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
	const [dropdownExpanded, setDropdownExpanded] = useState(false);

	const changeSelectedOption = (searchOption: SearchOption) => {
		const optionSelected = options.find(
			(item: MultiDropdownOption) => item.id === searchOption.value
		);
		setSelectedOption(optionSelected);
	};

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				const separateOptions = options.map((option: MultiDropdownOption) => ({
					label:
						selectedLanguage === "en"
							? option.name_en || option.name || ""
							: option.name || option.name_en || "",
					value: option.id,
				}));
				setSearchOptions(separateOptions);
			} catch (error) {
				console.error("Error fetching options:", error);
			}
		};

		fetchOptions();
	}, [options, selectedLanguage]);

	useEffect(() => {
		if (t("language") === "Taal") {
			setSelectedLanguage("nl");
		} else {
			setSelectedLanguage("en");
		}
	}, [t]);

	return (
		<TableContainer
			component={Paper}
			style={{
				maxHeight: dropdownExpanded ? "" : "50px",
				overflowY: dropdownExpanded ? "auto" : "hidden",
				boxShadow: "none",
				transition: "max-height 0.3s ease",
			}}
		>
			<div
				style={{
					minHeight:
						type === "client_options"
							? "20vh"
							: type === "estimate"
							? "30vh"
							: "40vh",
				}}
			>
				<Select
					options={searchOptions}
					onChange={(option) => {
						changeSelectedOption(option as SearchOption);
					}}
					onMenuOpen={() => setDropdownExpanded(true)}
					onMenuClose={() => setDropdownExpanded(false)}
				/>
			</div>
		</TableContainer>
	);
};

export default MultiDropdownTable;
