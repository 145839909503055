// src/screens/portfolio-screen/config/tarifConfig.ts

// Define tariffs for ERP and ERS
export const tarifErp = 104;  // Tarif for ERP in euros per hour
export const tarifErs = 104;  // Tarif for ERS in euros per hour

// Define other cost and pricing configurations if needed
export const defaultCurrency = "€";

// Export functions for calculating based on the tariff
export const calculateCostPerMinute = (minutes: number, tarif: number): number => {
    return (minutes * tarif) / 60;
};

export const calculateMarge = (sellingPrice: number, costErp: number, costErs: number, cost3rd: number): number => {
    return sellingPrice - costErp - costErs - cost3rd;
};

export const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
    };

    return new Intl.DateTimeFormat("en-CA", options).format(date).replace(", ", " ");
};



