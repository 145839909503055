import {initializeApp, getApp} from "firebase/app";
import {
	getAuth,
	signInWithPopup,
	OAuthProvider,
	signInWithEmailAndPassword,
	sendSignInLinkToEmail,
	sendEmailVerification,
	createUserWithEmailAndPassword,
	deleteUser,
	connectAuthEmulator,
} from "firebase/auth"; // Import OAuthProvider instead of AuthProvider
import {firebaseConfig} from "./firebase.config";
import {getFunctions, connectFunctionsEmulator, httpsCallable} from "firebase/functions";
import {getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {getStorage, connectStorageEmulator } from "firebase/storage";
import { add, List } from "lodash";

console.log("react app env", process.env.REACT_APP_ENV);
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "europe-west3");
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
	connectFirestoreEmulator(firestore, '127.0.0.1', 9000);
	connectStorageEmulator(storage, "127.0.0.1", 9002);
	connectFunctionsEmulator(functions, "127.0.0.1", 9003);
	connectAuthEmulator(auth, "http://127.0.0.1:9099");
	console.log("Connected to Firestore emulator:", firestore);
	console.log("Connected to Storage emulator:", storage);
	console.log("Connected to Functions emulator:", functions);
	// console.log("Connected to Auth emulator:", auth);

	const addCustomClaim = httpsCallable(functions, "addCustomClaim");
	const createUserAndAssignClaims = async (email: string, password: string, claims: List<string>) => {
		try {
		  const result = await addCustomClaim({
			isEmulator: true,
			email,         
			password,     
			claims,         
		  });
		  console.log(`Custom claims added successfully for ${email}:`, result.data);
		} catch (error) {
		  console.error(`Error adding custom claims for ${email}:`, error);
		}
	  };
	createUserAndAssignClaims('admin@praeter.nl', 'admin12', ["admin", "data-manager", "praeter-advisor"]);
	createUserAndAssignClaims('data@praeter.nl', 'data123', ["data-manager"]);
	createUserAndAssignClaims('advisor@praeter.nl', 'advisor12', ["praeter-advisor"]);
	createUserAndAssignClaims('screening@praeter.nl', 'screening12', ["energy-screening-advisor"]);
	createUserAndAssignClaims('solar@praeter.nl', 'solar12', ["solar-energy-advisor"]);
	createUserAndAssignClaims('saving@praeter.nl', 'saving12', ["energy-saving-advisor"]);

	const addFirebaseData = httpsCallable(functions, "addFirebaseData");
	const addFirebaseSpecificData = async (localFolderPath: string, destinationPrefix: string) => {
		try {
		  const result = await addFirebaseData({
			isEmulator: true,
			localFolderPath,         
			destinationPrefix,           
		  });
		  console.log(`Custom claims added successfully for ${localFolderPath}:`, result.data);
		} catch (error) {
		  console.error(`Error adding custom claims for ${localFolderPath}:`, error);
		}
	  };
	addFirebaseSpecificData('../firebase-storage/templates/', 'templates/');
	addFirebaseSpecificData('../firebase-storage/energy_saving_templates/', 'energy_saving_templates/');

}

// Create Microsoft OAuth provider instance
const microsoftProvider = new OAuthProvider("microsoft.com"); // Use OAuthProvider instead of AuthProvider

// Set custom parameters or scopes if needed
microsoftProvider.setCustomParameters({
	// Custom parameters here if required
	tenant: "9593e3be-446e-4a82-93d5-b78f71028516",
});

// Function to handle Microsoft sign-in with a popup
const signInWithMicrosoft = async () => {
	try {
		const result = await signInWithPopup(auth, microsoftProvider);
		// Handle successful login
		return {result};

		// Access and use the tokens or user data
	} catch (error: any) {
		// Handle errors
		console.error("Error signing in with Microsoft:", error.message);
	}
};

export {
	auth,
	signInWithMicrosoft,
	sendSignInLinkToEmail,
	signInWithEmailAndPassword,
	sendEmailVerification,
	createUserWithEmailAndPassword,
	deleteUser,
	firestore,
	storage,
	functions,
};
