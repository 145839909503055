import { useState, useEffect } from "react";
import {auth} from "../config/firebase";

const useCustomClaims = () => {
    const [customClaims, setCustomClaims] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchCustomClaims = async () => {
            if (auth.currentUser) {
                try {
                    const idTokenResult = await auth.currentUser.getIdTokenResult();
                    setCustomClaims(idTokenResult.claims);
                } catch (error) {
                    console.error("Error fetching custom claims", error);
                }
            } else {
                console.log("No user is currently signed in.");
            }
        };

        fetchCustomClaims();
    }, []);

    return customClaims;
};

export default useCustomClaims;
